// UserModal.js
import React from 'react';
import './WorkflowsModal.css';

const WorkflowsModal = ({ isOpen, onClose }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="workflows-modal">
      <div className="workflows-modal-content">
        <h2 className='workflows-title'>Workflows Modal</h2>
        {/* Add your modal content here */}
        <button onClick={onClose}>Close Modal</button>
      </div>
    </div>
  );
};

export default WorkflowsModal;
