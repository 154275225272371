import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { worxit, crusaders, freedom } from '../../../../ClientUpdater/ClientUpdater';
import '../ReportDetailsModal.css'
import { AtomHeaderDataState } from '../../../../RecoilState/AtomHeaderDataState';
import { useRecoilValue } from 'recoil';

const ExportReportToExcel = ({reportId}) =>  {

    const [reportDetailsExcel, setReportDetailsExcel] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const headerData = useRecoilValue(AtomHeaderDataState)
    const coNoValue = headerData[0].coNo;

    useEffect(() => {
        const fetchReportDetailsExcel = async () => {
          try {
            if(coNoValue == 'cc000'){
              const response = await axios.get(`https://worxit.co.za/api/${worxit}/view/viewReportDetailsExcel.php?reportId=${reportId}`);
              const newData = response.data.records;
              console.log("Response data:", newData); // Log the response data
              setReportDetailsExcel(newData);
              setIsLoading(false); // Set loading to false when data is fetched
            }
            // else if(coNoValue == 'cc004'){
            //   const response = await axios.get(`https://worxit.co.za/api/${crusaders}/view/viewReportDetailsExcel.php?reportId=${reportId}`);
            //   const newData = response.data.records;
            //   console.log("Response data:", newData); // Log the response data
            //   setReportDetailsExcel(newData);
            //   setIsLoading(false); // Set loading to false when data is fetched
            // }
            else if(coNoValue == 'cc0018'){
              const response = await axios.get(`https://worxit.co.za/api/${freedom}/view/viewReportDetailsExcel.php?reportId=${reportId}`);
              const newData = response.data.records;
              console.log("Response data:", newData); // Log the response data
              setReportDetailsExcel(newData);
              setIsLoading(false); // Set loading to false when data is fetched
            }
            
          } catch (error) {
            console.error("Error fetching report details: ", error);
            setIsLoading(false); // Set loading to false on error as well
          }
        };
    
        fetchReportDetailsExcel();
      }, [reportId]);

      const filteredExcelData = reportDetailsExcel.flatMap((report) => {
        const parsedReportData = JSON.parse(report.reportData);
      
        return parsedReportData.data.filter((field) => field.elementId !== 'et').map((field) => {
          let fieldValue = field.fieldData;
      
          // Convert boolean field value to "Yes" or "No"
          if (field.elementId === 'ebool') {
            fieldValue = field.fieldData ? 'Yes' : 'No';
          }
      
          return {
            id: field.id,
            elementId: field.elementId,
            fieldName: field.fieldName,
            fieldData: fieldValue,
          };
        });
      });

      function isValidUrl(url) {
        try {
          new URL(url);
          return true;
        } catch (error) {
          return false;
        }
      }
    
      const handleExportToExcel = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Report Details');
        
        // Add report names
        reportDetailsExcel.forEach((report) => {
          worksheet.addRow(["Report Name", report.reportName]);
        });
      
        // Add data
        filteredExcelData.forEach((field) => {
          if (Array.isArray(field.fieldData)) {
            field.fieldData.forEach((item) => {
              if (isValidUrl(item)) {
                // If the fieldData is a valid URL, add it as a hyperlink
                worksheet.addRow([field.fieldName, { text: item, hyperlink: item }]);
              } else if (field.elementId === 'egps') {
                // If the fieldData is GPS coordinates, show them as a single string separated by a comma
                worksheet.addRow([field.fieldName, `${item.latitude}, ${item.longitude}`]);
              } else {
                worksheet.addRow([field.fieldName, `${item.qtyNo}, ${item.barcode}, ${item.description}`]);
              }
            });
          } else {
            if (isValidUrl(field.fieldData)) {
              // If the fieldData is a valid URL, add it as a hyperlink
              worksheet.addRow([field.fieldName, { text: field.fieldData, hyperlink: field.fieldData }]);
            } else if (field.elementId === 'egps') {
              // If the fieldData is GPS coordinates, show them as a single string separated by a comma
              worksheet.addRow([field.fieldName, `${field.fieldData.latitude}, ${field.fieldData.longitude}`]);
            } else if (field.elementId === 'eqrscandisk' || field.elementId === 'e2dscandisk') {
              // If the fieldData is GPS coordinates, show them as a single string separated by a comma
              worksheet.addRow([field.fieldName, `Vehicle Reg: ${field.fieldData.vehicleReg}, Vehicle Make: ${field.fieldData.vehicleMake}, Vehicle Colour: ${field.fieldData.vehicleColour}, Vehicle Model: ${field.fieldData.vehicleModel}, Vehicle Expires: ${field.fieldData.vehicleExpires}, Expired Status: ${field.fieldData.vehicleMake}, Access Status: ${field.fieldData.accessStatus}`]);
            }
             else {
              worksheet.addRow([field.fieldName, field.fieldData]);
            }
          }
        });
        
        
      
        const reportNameId = reportDetailsExcel.map((report) => {
          return [`${report.reportName}_${report.reportId}`];
        });
      
        // Save the workbook
        const buffer = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buffer]), `${reportNameId}.xlsx`);
      };

    return (
        <div className='exportToExcelCont'>
            <button className='exportToExcelBtn' onClick={handleExportToExcel} disabled={isLoading}>
                Export to Excel
            </button>
        </div>
    );
}

export default ExportReportToExcel;