// YourComponent.jsx

import React from 'react';
import '../../FormBuilder.css';

const InputElement = ({ component, isRequired }) => {
  const renderElement = () => {
    return (
      component.elementName === 'input' && (
        <label className='titleTextLbl'>
          {component.fieldName}
          <span className='requiredContainer' style={{ color: 'red' }}>
            {isRequired(component) }
            
          </span>
          <input className='inputComponent' type="text" placeholder={component.fieldName} readOnly/>
        </label>
      )
    );
  };

  return (
    <div className='renderAllElements'>
      {renderElement()}
    </div>
  );
};

export default InputElement;
