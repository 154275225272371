import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaTimes } from 'react-icons/fa';
import './CreateUserModal.css';
import { useRecoilValue } from 'recoil';
import {AtomHeaderDataState} from '../../../../RecoilState/AtomHeaderDataState'


const CreateUserModal = ({ isOpen, onClose }) => {
    const headerData = useRecoilValue(AtomHeaderDataState)
    const coNoValue = headerData[0].coNo;
    const [viewCompanyNo, setViewCompanyNo] = useState([]);
    const [selectedCoNo, setSelectedCoNo] = useState('');
    // const [viewZones, setViewZones] = useState([]);
    const [selectedZones, setSelectedZones] = useState('');
    const [message, setMessage] = useState("");
    const [formData, setFormData] = useState({
        username: '',
        role: '',
        password: '',
        confirmPassword: '',
        empNo: ''
        // Add other fields as needed
    });

    // useEffect(() => {
    //     const fetchCompanyNos = async () => {
    //         try {
    //             const response = await axios.get(`https://worxit.co.za/api/${crusaders}/fetch/fetchCompanyNo.php`);
    //             const newData = response.data.records;
    //             setViewCompanyNo(newData);
    //             console.log('newData: ', newData)
    //           } catch (error) {
    //             console.error("Error fetching data: ", error);
    //           }
    //     };
    
    //     fetchCompanyNos(); // Initial call
    //     const intervalId = setInterval(fetchCompanyNos, 5000);
    //     return () => clearInterval(intervalId);
    //   }, [setViewCompanyNo]); // Empty dependency array to run this effect only once

      // useEffect(() => {
      //   const fetchZones = async () => {
      //       try {
      //           const response = await axios.get(`https://worxit.co.za/api/${updatedValue}/fetch/fetchZones.php`);
      //           const newData = response.data.records;
      //           setViewZones(newData);
      //           console.log('newData: ', newData)
      //         } catch (error) {
      //           console.error("Error fetching data: ", error);
      //         }
      //   };
    
      //   fetchZones(); // Initial call
      //   const intervalId = setInterval(fetchZones, 5000);
      //   return () => clearInterval(intervalId);
      // }, [setViewZones]); // Empty dependency array to run this effect only once

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
        ...formData,
        [name]: value,
        });
    };

    const handleSubmit=(e)=>{
      e.preventDefault();
  
      // if (data.password !== data.confirmPassword) {
      //   setMessage("Passwords do not match.");
      // return;
      // }
  
     const sendData={
        username:formData.username,
        password:formData.password,
        confirmPassword:formData.confirmPassword,
        empNo:formData.empNo,
        role:formData.role,
        coNo:coNoValue,
        //zone: selectedZones

      }
      console.log(sendData)
      axios.post(`https://worxit.co.za/api/createUsers.php`,sendData)
      .then((res)=> {
        if (res.data.success) {
          onClose();  // Close the modal only if the response is true
          setFormData({ deviceId: "", coNo: "" });
        } else {
          setMessage(res.data.message);
        }
        // setMessageSuc(res.data.messageSuc);
        // setMessageError("");
        // setFormData({username:"", password:"",confirmPassword:"",role:"",coNo:"", zone:""})
      })
      .catch((res) => {
        console.error(res);
        setMessage(res.data.message);
       
      });
    }

    if (!isOpen) {
        return null;
    }

  return (
    <div className="create-user-modal">
      <div className="create-user-modal-content">
        <div className='modalHeader'>
          <div className='header-title-cont'>
            <h3 className="create-user-title">Create User</h3>
          </div>
            <div className='defaultBtnCont'>
              <span onClick={handleSubmit} className='defaultBtn' type="submit">Add User</span>
            </div>
            <div onClick={onClose} className="closeContAllModals">
              <span className="closeAllModalIcon">
                <FaTimes />
              </span>
          </div>
        </div>
            <div className='createUserUsernameCont'>
              <div className='createUserUsernameTitle'>
                 <label className='searchName'>
              Username:
              </label>
              </div>
              <div className='createUserUsername'>
                <input
                  className='createUserUsernameInput'
                  type="text"
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className='createUserUsernameCont'>
              <div className='createUserUsernameTitle'>
                 <label className='searchName'>
              Password:
              </label>
              </div>
              <div className='createUserUsername'>
                <input
                  className='createUserUsernameInput'
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className='createUserUsernameCont'>
              <div className='createUserUsernameTitle'>
                 <label className='searchName'>
              Confirm Password:
              </label>
              </div>
              <div className='createUserUsername'>
                <input
                  className='createUserUsernameInput'
                  type="password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className='createUserEmpNoCont'>
              <div className='createUserEmpNoTitle'>
                <label className='searchName'>
              Employee No:
              </label>
              </div>
              <div className='createUserEmpNo'>
                <input
                className='createUserEmpNoInput'
                type="text"
                name="empNo"
                value={formData.empNo}
                onChange={handleChange}
              />
              </div>            
            </div>
            <div className='createUserRoleCont'>
              <div className='createUserRoleTitle'>
                <label className='searchName'>
              Role:
              </label>
              </div>
              <div className='createUserRole'>
                <input
                className='createUserRoleInput'
                type="text"
                name="role"
                value={formData.role}
                onChange={handleChange}
              />
              </div>            
            </div>
            <div className='createUserSelectedCoNoCont'>
              <div className='createUserCompanyNoTitle'>
                  <label className='searchName'>Company No:</label>
              </div>
              <label className='searchName'>
              {coNoValue}
              </label>
            </div>
            {/* <div className='createUserSelectedZoneCont'>
              <div className='createUserZoneTitle'>
                <label className='searchName' htmlFor="searchZoneDropdown">Zone:</label>
              </div>
              <div className='dropdownZoneCont'>
                  <select
                  id="searchZoneDropdown"
                  value={selectedZones}
                  onChange={(e) => setSelectedZones(e.target.value)}
                >
                  <option value="">-- Select --</option>
                  {viewZones.map((item) => (
                    <option key={item.id} value={item.zoneId}>
                      {item.zoneId}
                    </option>
                  ))}
                </select>
              </div>
            </div> */}
             
             <div className='messagesCont'>
                {message && <p className='error-message'>{message}</p>}
             </div>          
      </div>
    </div>
  );
};

export default CreateUserModal;
