// UserModal.js
import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { FaTimes } from 'react-icons/fa';
import ReactPaginate from 'react-paginate';
import Loader from '../../../../Loader/Loader';
import CreateUserModal from '../CreateUserModal/CreateUserModal';
import ViewUserDetailsModal from '../ViewUserDetailsModal/ViewUserDetailsModal';
import { worxit, crusaders, freedom } from '../../../../ClientUpdater/ClientUpdater';
import {AtomHeaderDataState} from '../../../../RecoilState/AtomHeaderDataState'
import './UserModal.css';
import { useRecoilValue } from 'recoil';


const UserModal = ({ isOpen, onClose }) => {
  
  const [viewUsers, setViewUsers]= useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchColumn, setSearchColumn] = useState(""); 
  const [searchKeyword, setSearchKeyword] = useState(""); 
  const [isCreateUserModalOpen, setCreateUserModalOpen] = useState(false);
  const [isViewUserDetailsModalOpen, setViewUserDetailsModalOpen] = useState(false);
  
  const [selectedUser, setSelectedUser] = useState(null);
  const headerData = useRecoilValue(AtomHeaderDataState)
  const coNoValue = headerData[0].coNo;
  const roleValue = headerData[0].role
  // const [message, setMessage] = useState("");
    
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const usersPerPage = rowsPerPage;
  const [pageNumber, setPageNumber] = useState(0);

  
console.log("roleValue: ", roleValue)
  const usersTable = async () => {
    try {
      const response = await axios.get(`https://worxit.co.za/api/viewUsers.php`);
      const newData = response.data.records;
  
      // Assuming you have a coNoValue variable
      const filteredData = newData.filter((user) => user.coNo === coNoValue);
     
      if(roleValue === 0){
        setViewUsers(newData);
      }else{
        setViewUsers(filteredData);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data: ", error);
    }
  };
  
    
useEffect(() => {
    usersTable(); // Initial call
    const intervalId = setInterval(usersTable, 3000);
    return () => clearInterval(intervalId);
  }, [setViewUsers]); // Empty dependency array to run this effect only once
  
  
  // const filteredSearch = viewUsers.filter((user) => {
  //   if (searchColumn !== "") {
  //     return user[searchColumn].toLowerCase().includes(searchKeyword.toLowerCase());
  //   }
  //   // If "All" is selected, check if any column contains the search keyword
  //   return Object.values(user).some((value) =>
  //     value.toLowerCase().includes(searchKeyword.toLowerCase())

      
  //   );

    
  // });
  const filteredSearch = viewUsers.filter((user) => {
    // Check if the user matches the search criteria
    const matchesSearch =
      searchColumn !== ""
        ? user[searchColumn].toLowerCase().includes(searchKeyword.toLowerCase())
        : Object.values(user).some((value) =>
            value.toLowerCase().includes(searchKeyword.toLowerCase())
          );
  
    // Check if the user matches the role criteria based on the logged-in user's role
    const matchesRole =
    headerData[0]?.role === 3 ? user.role === '3' : true;
  
    // Combine the search and role criteria
    return matchesSearch && matchesRole;
  });

  const editForm = (user) => {
    setSelectedUser(user);
    setViewUserDetailsModalOpen(true);
  };
  
    
  
  //   const deleteUser = (id) =>{
  //     axios.delete(`https://worxit.co.za/api/${crusaders}/delete/deleteUser.php`, {data: {id: id}})
  //     .then(()=>{
  //       usersTable();
  //     }).catch(()=>{
  //         alert("Error, Cannot Delete User!");
  //     })
  // }

  if (!isOpen) {
    return null;
  }

  return (
    <div className="user-modal">
      <div className="user-modal-content">
        <div className='modalHeader'>
          <div className='header-title-cont'>
          <h3 className='user-title'>User</h3>
        </div>
          {headerData[0]?.role === 0 || headerData[0]?.role === 1 || headerData[0]?.role === 2 || headerData[0]?.role === 3 ? (
            <div className='defaultBtnCont'> 
          <span className='defaultBtn' onClick={() => setCreateUserModalOpen(true)}>Add User</span>
          </div>
          ) : null
          
          }
        <div onClick={onClose} className='closeContAllModals' >
          <span className='closeAllModalIcon'>
            <FaTimes />
          </span>
          </div>
        </div>
        
        <div className="row filter">
          <div className="column1AllUsers">
            <label className='searchByTitle'>Search By:</label>
          </div>
          <div className="column2AllUsers">
            <select
              className="searchInput"
              value={searchColumn}
              onChange={(e) => setSearchColumn(e.target.value)}
            >
              <option value="">All</option>
              <option value="username">Username</option>
              <option value="role">Role</option>
              <option value="coNo">Company No</option>
            </select>
          </div>
        
          <div className="column3AllUsers">
            <input
              type="text"
              className="searchInput"
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
            />
          </div>
        </div>
        
        
        {/* <h7>Latest 5 Orders Completed...</h7> */}
        <table className="usersTbl">
            <thead>
                <tr>
                    <th>Username</th>
                    <th>Company No</th>
                    <th>Employee No</th>
                    <th>Role</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
              {isLoading ? (
                  <Loader />
                ) : filteredSearch.length > 0 ? (
                  // .sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime))
                  filteredSearch
                    .slice(pageNumber * usersPerPage, (pageNumber + 1) * usersPerPage)
                    .map((viewUsers, index) => (
                      <tr key={index} onClick={() => editForm(viewUsers)}>
                        <td>{viewUsers.username}</td>
                        <td>{viewUsers.coNo}</td>
                        <td>{viewUsers.empNo}</td>
                        <td>{viewUsers.role}</td>
                        <td>
                          <div
                            className={`statusCircle ${
                              viewUsers.status === '1' ? 'green' : 'red'
                            }`}
                          ></div>
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td colSpan="6">No Records Found</td>
                  </tr>
                )}

                </tbody>
            </table>   
            <div className="rows-per-page">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={Math.ceil(filteredSearch.length / usersPerPage)}
                onPageChange={({ selected }) => setPageNumber(selected)}
                containerClassName={"pagination"}
                previousLinkClassName={"pagination__link"}
                nextLinkClassName={"pagination__link"}
                disabledClassName={"pagination__link--disabled"}
                activeClassName={"pagination__link--active"}
              />
            </div>
            
          {isCreateUserModalOpen && (
          <CreateUserModal 
            isOpen={isCreateUserModalOpen} 
            onClose={() => setCreateUserModalOpen(false)} />
        )}

        {isViewUserDetailsModalOpen && (
          <ViewUserDetailsModal 
            isOpen={isViewUserDetailsModalOpen} 
            onClose={() => setViewUserDetailsModalOpen(false)} 
            user={selectedUser}/>
        )}
      </div>
    </div>
  );
};

export default UserModal;
