// UserModal.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './FormBuilderModal.css';
import CreateForm from './CreateForm';
import { AtomFormsDataState } from '../../../RecoilState/AtomFormsDataState';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { FaPencilAlt, FaTimes } from 'react-icons/fa';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import { worxit, crusaders, freedom } from '../../../ClientUpdater/ClientUpdater';
import ReactPaginate from 'react-paginate';
import { AtomHeaderDataState } from '../../../RecoilState/AtomHeaderDataState';

const FormBuilderModal = ({ isOpen, onClose }) => {
  const headerData = useRecoilValue(AtomHeaderDataState)
  const coNoValue = headerData[0].coNo;
  const [columns, setColumns] = useState([]);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showSelectedForm, setShowSelectedForm] = useState(false);
  const setFormsCreated = useSetRecoilState(AtomFormsDataState);
  const formsCreated = useRecoilValue(AtomFormsDataState);
  const [selectedForm, setSelectedForm] = useState(null);
  const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [formToDelete, setFormToDelete] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const reportsPerPage = 5; // Set the number of reports to display per page
  const pagesVisited = pageNumber * reportsPerPage;
  const [searchTerm, setSearchTerm] = useState('');


  useEffect(() => {
    const viewCreatedForms = async () => {
      try {
        if(coNoValue === 'cc000'){
          const response = await axios.get(`https://worxit.co.za/api/${worxit}/view/viewForms.php`);
          const newData = response.data.records;
          setFormsCreated(newData);
        }
        // else if(coNoValue === 'cc004'){
        //   const response = await axios.get(`https://worxit.co.za/api/${crusaders}/view/viewForms.php`);
        //   const newData = response.data.records;
        //   setFormsCreated(newData);
        // }
        else if(coNoValue === 'cc0018'){
          const response = await axios.get(`https://worxit.co.za/api/${freedom}/view/viewForms.php`);
          const newData = response.data.records;
          setFormsCreated(newData);
        }
       
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    viewCreatedForms(); // Initial call
    const intervalId = setInterval(viewCreatedForms, 3000);
    return () => clearInterval(intervalId);
  }, [setFormsCreated]); // Empty dependency array to run this effect only once

  const filteredData = formsCreated.filter(
    (item) => item.elementId === 'et' && item.fieldName.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const selectedFormClick = (formId) => {
    setShowSelectedForm(true);

    const selectedForms = formsCreated.filter((item) => item.formId === formId);

    if (selectedForms.length > 0) {
      setSelectedForm(selectedForms);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setPageNumber(0); // Reset page number when searching
  };

  const createFormClick = () => {
    setShowCreateForm(true);
    setColumns([]);
  };

  const deleteForm = (formId) => {
    setFormToDelete(formId);
    setDeleteConfirmationOpen(true);
  };

  const confirmDelete = async () => {
    try {
      if(coNoValue === 'cc000'){
        await axios.delete(`https://worxit.co.za/api/${worxit}/delete/deleteForm.php?formId=${formToDelete}`);
        setDeleteConfirmationOpen(false);
      }
      // else if(coNoValue === 'cc004'){
      //   await axios.delete(`https://worxit.co.za/api/${crusaders}/delete/deleteForm.php?formId=${formToDelete}`);
      //   setDeleteConfirmationOpen(false);
  
      // }
      else if(coNoValue === 'cc0018'){
        await axios.delete(`https://worxit.co.za/api/${freedom}/delete/deleteForm.php?formId=${formToDelete}`);
        setDeleteConfirmationOpen(false);
  
      }
    } catch (error) {
      console.error("Error deleting form: ", error);
    }
  };

  const cancelDelete = () => {
    setFormToDelete(null);
    setDeleteConfirmationOpen(false);
  };

  if (!isOpen) {
    return null;
  }

  const pageCount = Math.ceil(filteredData.length / reportsPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div className="FormBuilder-modal">
      <div className="FormBuilder-modal-content">
        <div className='modalHeader'>
          <div className='header-title-cont'>
            <h3 className="FormBuilder-title">Form Builder</h3>
          </div>
          <div className='formBuilderBtnCont'>
            <span className='formBuilderBtn' onClick={createFormClick}>Create Form</span>
          </div>
          <div onClick={onClose} className='closeContAllModals'>
            <span className='closeAllModalIcon'>
              <FaTimes />
            </span>
            </div>
        </div>
        
        
        <div>
          
          <div className='searchFormNameCont'>
            <div className='searchFormCont'>
              <label className='searchName' htmlFor="searchDropdown">Search Form Name:</label>
            </div>
            <div>
              <input
                type="text"
                placeholder="Search by form name"
                value={searchTerm}
                onChange={handleSearch}
                className="searchInput"
              />
            </div>
          </div>
          <div className="formsListTitleCont">
            <h4 className="formsListTitle">Saved Forms</h4>
          </div>
          <table className='formBuilderTable'>
            <thead>
              <tr>
                <th>Form Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredData
                .slice(pagesVisited, pagesVisited + reportsPerPage)
                .map(form => (
                  <tr key={form.id2}>
                    <td>{form.fieldName}</td>
                    <td>
                      <div className='editDeleteIcons'>
                        <div className='editRow'>
                          <span title='Edit Form' className='editRowIcon' onClick={() => selectedFormClick(form.formId)}>
                            <FaPencilAlt />
                          </span>
                        </div>
                        <div className='deleteRow'>
                          <span title='Delete Form' className='deleteRowIcon' onClick={() => deleteForm(form.formId)}>
                            <FaTimes />
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <ReactPaginate
            previousLabel={'<<'}
            nextLabel={'>>'}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={'pagination'}
            activeClassName={'active'}
          />
        </div>
        
      </div>
      {showCreateForm && (
        <CreateForm
          columns={columns}
          setColumns={setColumns}
          isOpen={showCreateForm}
          onRequestClose={() => setShowCreateForm(false)}
          setShowCreateForm={setShowCreateForm}
        />
      )}
      {showSelectedForm && (
        <CreateForm
          columns={columns}
          setColumns={setColumns}
          selectedForm={selectedForm}
          setSelectedForm={setSelectedForm}
          isOpen={showSelectedForm}
          setShowSelectedForm={setShowSelectedForm}
          showSelectedForm={showSelectedForm}
          onRequestClose={() => setShowSelectedForm(false)}
        />
      )}
      <DeleteConfirmationModal
        isOpen={isDeleteConfirmationOpen}
        onConfirm={confirmDelete}
        onCancel={cancelDelete}
        selectedForm={selectedForm}
      />
    </div>
  );
};

export default FormBuilderModal;
