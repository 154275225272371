// UserModal.js
import React from 'react';
import './PatrolsModal.css';

const PatrolsModal = ({ isOpen, onClose }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="patrols-modal">
      <div className="patrols-modal-content">
        <h2 className='patrols-title'>Patrols Modal</h2>
        {/* Add your modal content here */}
        <button onClick={onClose}>Close Modal</button>
      </div>
    </div>
  );
};

export default PatrolsModal;
