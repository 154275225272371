// import React from 'react';
// import './Loader.css'; // Import your CSS file

// const Loader = () => {
//   return (
//     <div className="wrapper">
//       <div className="loader">
//         <svg className="loading-animation" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
//           <path className="loading-animation__path" d="M17.9 91.1V8.9h33.5s28.8-1.4 28.8 24.7c0 19.6-17.5 23.2-17.5 23.2L85 91.2H69.8l-21-33h-8.1V47.1h9.6s16.1 1.6 16.1-13.5-16.1-13.4-16.1-13.4H31.1v70.9H17.9z"/>
//         </svg>
//       </div>
//     </div>
//   );
// };

// export default Loader;

import React from 'react';
import styled, { keyframes } from 'styled-components';

const SpinnerContainer = styled.div`
  position: fixed;
  z-index: 10000;
  width: 110px;
  height: 154px;
  top: 30%;
  left: 50%;
  margin-left: -77px;
  margin-top: -77px;
  border-radius: 8px;

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 50px;
    width: 50px;
    margin-left: -25px;
    margin-top: -40px;

    div {
      background: #fff;
      width: 12%;
      height: 35%;
    }
  }

  .spinner-message {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 15px;
    text-align: center;
    color: #000;
    font-size: 16px;
    font-weight: bold;
  }
`;

const spin = keyframes`
  from { opacity: 1; }
  to { opacity: 0.25; }
`;

const Spinner = styled.div`
  position: relative;
  width: 50px;
  height: 50px;
  display: inline-block;

  > div {
    width: 9%;
    height: 35%;
    background: #000;
    position: absolute;
    left: 44.5%;
    top: 37%;
    opacity: 0;
    animation: ${spin} 1s linear infinite;
    border-radius: 50px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);

    ${({ spinnerTickCount }) => {
      let styles = '';

      for (let i = 1; i <= spinnerTickCount; i++) {
        const rotation = (i - 1) * (360 / spinnerTickCount);
        const fadeTiming = -1 * (1 - (i - 1) * (1 / spinnerTickCount));

        styles += `
          &.bar${i} {
            transform: rotate(${rotation}deg) translate(0, -142%);
            animation-delay: ${fadeTiming}s;
          }
        `;
      }

      return styles;
    }}
  }
`;

const Loader = ({ spinnerTickCount = 12 }) => (
  <SpinnerContainer>
    <Spinner spinnerTickCount={spinnerTickCount}>
      {[...Array(spinnerTickCount)].map((_, i) => (
        <div key={i} className={`bar${i + 1}`} />
      ))}
    </Spinner>
    <div className="spinner-message">Loading...</div>
  </SpinnerContainer>
);

export default Loader;


