import React from 'react';
import '../../FormBuilder.css';

const DatePickerElement = ({ component, isRequired }) => {
  const renderElement = () => {
    return (
      component.elementName === 'datePicker' && (
        <label className='titleTextLbl'>
            {component.fieldName }
            <span className='requiredContainer' style={{ color: 'red' }}>{isRequired(component)}</span>
            <input className='inputComponent' type="date" />
        </label>
      )
    );
  };

  return (
    <div className='renderAllElements'>
      {renderElement()}
    </div>
  );
};

export default DatePickerElement;
