import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
//import 'leaflet-geosearch/dist/geosearch.css';

const MapView = () => {

    
    const lat = "-30"
    const long = "31"
    const position = [lat, long]
    return (
        // <div className="MainContainer">
        //     <h4>Map</h4>
        // </div>
        <div className="MainContainer">
            <MapContainer center={position} zoom={8} style={{ height: '100vh', width: '100%' }} scrollWheelZoom={true}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
            </MapContainer>
        </div>
        
    );
}

export default MapView;