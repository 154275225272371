import React, { useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';
import MapView from '../../components/MapView/MapView'

const Main = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(true);
    const burgerClass = isSidebarOpen ? 'burger-menu open' : 'burger-menu';
    const burgerMenu = isOpen ? 'burger-menu' : 'open' 
    const burgerMerge = isOpen ? 'burger--merge' : ''; 
    const [isSettingsDropdownOpen, setIsSettingsDropdownOpen] = useState(false);
    const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);

    const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
    };
    
     // Function to close the burger menu
  const closeBurgerMenu = () => {
    setIsOpen(true);
  };

    return (
        <div>
          <Navbar 
            toggleSidebar={toggleSidebar} 
            burgerClass={burgerClass} 
            burgerMenu={burgerMenu} 
            burgerMerge={burgerMerge} 
            isOpen={isOpen} 
            setIsOpen={setIsOpen}
            setIsSidebarOpen={setIsSidebarOpen}
            closeBurgerMenu={closeBurgerMenu}
            isSettingsDropdownOpen={isSettingsDropdownOpen}
            setIsSettingsDropdownOpen={setIsSettingsDropdownOpen}
            isUserDropdownOpen={isUserDropdownOpen}
            setIsUserDropdownOpen={setIsUserDropdownOpen}
          /> 
          <Sidebar 
            isOpen={isSidebarOpen} 
            setIsSidebarOpen={setIsSidebarOpen}  
            burgerClass={burgerClass} 
            burgerMenu={burgerMenu} 
            burgerMerge={burgerMerge}
            closeBurgerMenu={closeBurgerMenu}
            isSettingsDropdownOpen={isSettingsDropdownOpen}
            setIsSettingsDropdownOpen={setIsSettingsDropdownOpen}
            isUserDropdownOpen={isUserDropdownOpen}
            setIsUserDropdownOpen={setIsUserDropdownOpen}
          />
          <MapView/>
        </div>
    
    
        
    );
}

export default Main;