import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { AtomReportNamesState } from '../../../RecoilState/AtomReportNamesState';
import './ReportsModal.css';
import { useRecoilState, useRecoilValue } from 'recoil';
import ReportDetailsModal from './ReportDetailsModal';
import ReportsLoader from './ReportsLoader/ReportsLoader';
import { DateRangePicker } from 'rsuite';
import { format } from 'date-fns';
import subDays from 'date-fns/subDays';
import ReactPaginate from 'react-paginate';
import { FaTimes } from 'react-icons/fa';
import { LiaTimesSolid } from "react-icons/lia";
import { worxit, crusaders, freedom } from '../../../ClientUpdater/ClientUpdater';
import { AtomHeaderDataState } from '../../../RecoilState/AtomHeaderDataState';

//import 'rsuite/dist/rsuite.min.css';

const ReportsModal = ({ isOpen, onClose }) => {
  const headerData = useRecoilValue(AtomHeaderDataState)
  const coNoValue = headerData[0].coNo;
  const [viewFormNames, setViewFormNames] = useRecoilState(AtomReportNamesState);
  const [viewSites, setViewSites] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]);
  const [selectedField, setSelectedField] = useState('');
  const [searchSite, setSearchSite] = useState('');
  const [searchValue, setSearchValue] = useState('');
  // const [selectedFromDate, setSelectedFromDate] = useState('');
  // const [selectedEndDate, setSelectedEndDate] = useState('');
  const [searchClicked, setSearchClicked] = useState(false);
  const [selectedReportId, setSelectedReportId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [dateRange, setDateRange] = useState([null, null]);
  const [pageNumber, setPageNumber] = useState(0);
  const reportsPerPage = 10; // Set the number of reports to display per page
  const pagesVisited = pageNumber * reportsPerPage;

  const displayReports = filteredReports
  .sort((a, b) => new Date(b.create_on) - new Date(a.create_on)) // Sort in descending order based on create_on
  .slice(pagesVisited, pagesVisited + reportsPerPage)
  .map((report) => (
    <tr className='selectReport' key={report.id} onClick={() => handleReportClick(report.reportId)}>
      <td>{report.reportId}</td>
      <td >{report.reportName}</td>
      <td>{report.create_on}</td>
    </tr>
  ));

  const pageCount = Math.ceil(filteredReports.length / reportsPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  useEffect(() => {
    const fetchCreatedFormNames = async () => {
      try {
        if(coNoValue === 'cc000'){
          const response = await axios.get(`https://worxit.co.za/api/${worxit}/view/viewFormNames.php`);
          const newData = response.data.records;
          setViewFormNames(newData);
        }
        // else if(coNoValue === 'cc004'){
        //   const response = await axios.get(`https://worxit.co.za/api/${crusaders}/view/viewFormNames.php`);
        //   const newData = response.data.records;
        //   setViewFormNames(newData);
        // }
        else if(coNoValue === 'cc0018'){
          const response = await axios.get(`https://worxit.co.za/api/${freedom}/view/viewFormNames.php`);
          const newData = response.data.records;
          setViewFormNames(newData);
        }
      

      } catch (error) {
        console.error("Error fetching data: ", error);

      }
    };

    fetchCreatedFormNames(); // Initial call
    const intervalId = setInterval(fetchCreatedFormNames, 5000);
    return () => clearInterval(intervalId);
  }, [setViewFormNames]); // Empty dependency array to run this effect only once

  useEffect(() => {
    const fetchSites = async () => {
      try {
        if(coNoValue === 'cc000'){
          const response = await axios.get(`https://worxit.co.za/api/${worxit}/view/viewSites.php`);
          const newData = response.data.records;
          setViewSites(newData);
        }
        // else if(coNoValue === 'cc004'){
        //   const response = await axios.get(`https://worxit.co.za/api/${crusaders}/view/viewSites.php`);
        //   const newData = response.data.records;
        //   setViewSites(newData);
        // }
        else if(coNoValue === 'cc0018'){
          const response = await axios.get(`https://worxit.co.za/api/${freedom}/view/viewSites.php`);
          const newData = response.data.records;
          setViewSites(newData);
        }
      

      } catch (error) {
        console.error("Error fetching data: ", error);

      }
    };

    fetchSites(); // Initial call
    const intervalId = setInterval(fetchSites, 5000);
    return () => clearInterval(intervalId);
  }, [setViewSites]); // Empty dependency array to run this effect only once


  useEffect(() =>{
    console.log('viewSites: ', viewSites)
  })

   const handleSearch = async () => {
   
    try {
      if(coNoValue === 'cc000'){
        const response = await axios.get(`https://worxit.co.za/api/${worxit}/view/viewReportsData.php`, {
          params: {
            create_from: dateRange && dateRange[0]
              ? format(dateRange[0], 'yyyy-MM-dd 00:00:00') // Set time to start of the day
                : '',
            create_to: dateRange && dateRange[1]
              ? format(dateRange[1], 'yyyy-MM-dd 23:59:59') // Set time to end of the day
                : '',
        reportName: selectedField,
        searchSite: searchSite,
        searchValue: searchValue,
          },
        });
        const newData = response.data.records;
        console.log("API Response: ", response.data);
      
        setFilteredReports(newData);
        setIsLoading(false); 
        setSearchClicked(true);
        setPageNumber(0);
        console.log("recordData: ", newData)
      }
      // else if(coNoValue === 'cc004' ){
      //   const response = await axios.get(`https://worxit.co.za/api/${crusaders}/view/viewReportsData.php`, {
      //     params: {
      //        create_from: dateRange && dateRange[0]
          //     ? format(dateRange[0], 'yyyy-MM-dd 00:00:00') // Set time to start of the day
          //     : '',
          // create_to: dateRange && dateRange[1]
          //   ? format(dateRange[1], 'yyyy-MM-dd 23:59:59') // Set time to end of the day
            //  : '',
      //       reportName: selectedField,
      //       searchValue: searchValue,
      //     },
      //   });
      //   const newData = response.data.records;
      //   console.log("API Response: ", response.data);
  
      //   setFilteredReports(newData);
      //   setIsLoading(false); 
      //   setSearchClicked(true);
      //   setPageNumber(0);
      //   console.log("recordData: ", newData)
      // }
      else if(coNoValue === 'cc0018' ){
        const response = await axios.get(`https://worxit.co.za/api/${freedom}/view/viewReportsData.php`, {
          params: {
            create_from: dateRange && dateRange[0]
            ? format(dateRange[0], 'yyyy-MM-dd 00:00:00') // Set time to start of the day
              : '',
            create_to: dateRange && dateRange[1]
              ? format(dateRange[1], 'yyyy-MM-dd 23:59:59') // Set time to end of the day
                : '',
            reportName: selectedField,
            searchSite: searchSite,
            searchValue: searchValue,
          },
        });
        const newData = response.data.records;
        console.log("API Response: ", response.data);
  
        setFilteredReports(newData);
        setIsLoading(false); 
        setSearchClicked(true);
        setPageNumber(0);
        console.log("recordData: ", newData)
      }
      
    } catch (error) {
      console.error("Error fetching data: ", error);
      setIsLoading(false); 
    }
  };

  const handleReportClick = (reportId) => {
    setSelectedReportId(reportId);
  };

  
   const resetData = () => {
    setFilteredReports([]);
    setSelectedField('');
    setSearchSite('');
    setSearchValue('');
    setSelectedReportId(null);
    setDateRange([null, null]);
    setPageNumber(0);
    setSearchClicked(false);
  };

  const handleCloseModal = () => {
    onClose();
    resetData();
  };

  if (!isOpen) {
    return null;
  }

  console.log('dateRange: ', dateRange)
   console.log('filteredReports: ', filteredReports)

   
  return (
    <div className="reports-modal">
      <div className="reports-modal-content">
        <div className='searchCont'>
          <div className='searchTitleColumn'>
            <label className='searchTitle'>Search</label>
          </div>
          <div className='searchColumn'>
            <div className='searchDateCont'>
              <div className='searchNameCont'>
                <label className='searchName'>Date:</label>
              </div>
              <div className='searchDate'>
              <DateRangePicker
                className='dateInput'
                value={dateRange}
                onChange={(value) => {
                  console.log('Selected date range:', value);
                  setDateRange(value);
                }}
                onClean={resetData}
                placeholder={"Search Date Range"}
                
              />
            </div>
          
            </div>
            <div className='searchReportNameCont'>
              <div className='searchNameCont'>
                <label className='searchName' htmlFor="searchDropdown">Report Name:</label>
              </div>
              <div className='dropdownCont'>
                <select
                id="searchDropdown"
                value={selectedField}
                onChange={(e) => setSelectedField(e.target.value)}
              >
                <option value="">-- Select --</option>
                {viewFormNames.map((item) => (
                  <option key={item.id2} value={item.fieldName}>
                    {item.fieldName}
                  </option>
                ))}
              </select>
              </div>
              <div onClick={resetData} className='clearReportNameDropCont' >
                <span className='clearReportNameDropIcon'>
                  <LiaTimesSolid className='liaTimesSolid'/>
                </span>
              </div>
            </div>
            
            <div className='searchReportNameCont'>
              <div className='searchNameCont'>
                <label className='searchName' htmlFor="searchDropdown">Site:</label>
              </div>
              <div className='dropdownCont'>
                <select
                id="searchDropdown"
                value={searchSite}
                onChange={(e) => setSearchSite(e.target.value)}
              >
                <option value="">-- Select --</option>
                {viewSites.map((item) => (
                  <option key={item.id} value={item.site}>
                    {item.site}
                  </option>
                ))}
              </select>
              </div>
              <div onClick={resetData} className='clearSiteDropCont' >
                <span className='clearSiteDropContIcon'>
                  <LiaTimesSolid className='liaTimesSolid'/>
                </span>
              </div>
            </div>
            
            <div className='searchAnythingCont'>
              <div className='searchNameCont'>
                <label className='searchName' htmlFor="searchDropdown">Find</label>
              </div>
              <div className='searchAnything'>
                 <input
                    className='inputSearchAnythingComponent'
                    type="text"
                    placeholder='Search for anything'
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
              />
              </div>
              <div onClick={resetData} className='clearSearchAnythingCont' >
                <span className='clearSearchAnythingIcon'>
                  <LiaTimesSolid className='liaTimesSolid'/>
                </span>
              </div>
            </div>
          </div>
          <div className='searchBtnCont'>
            <button className='searchBtn' onClick={handleSearch}>Search</button>
          </div>
        </div>
        
          
          {searchClicked && filteredReports.length > 0 ? (
              <>
            {isLoading ? (
              <div className='reportModalLoaderCont'>
                < ReportsLoader />
              </div>
            ): (
              <div className='reportsTableCont'>
                <table className='reportsTable'>
                  <thead>
                    <tr>
                      <th>Report ID</th>
                      <th>Report Name</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody >{displayReports}</tbody>
                </table>
                <ReactPaginate
                  previousLabel={'<<'}
                  nextLabel={'>>'}
                  pageCount={pageCount}
                  onPageChange={changePage}
                  containerClassName={'pagination'}
                  activeClassName={'active'}
                />
              </div>
              )}
              </>
            ) : searchClicked ? (
              <div className='noRecordFoundCont'>
                <label className='searchName'> No records found</label>
              </div>
            ) : null}

        {selectedReportId && (
          <ReportDetailsModal 
            reportId={selectedReportId} 
            onClose={() => setSelectedReportId(null)} />
        )}
        <div onClick={handleCloseModal} className='closeContReportModal' >
          <span className='closeReportModalIcon'>
            <FaTimes />
          </span>
        </div>
      </div>
    </div>
  );
};

export default ReportsModal;
