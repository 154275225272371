import React from 'react';
import '../../FormBuilder.css';

const InstructionsElement = ({ component, isRequired }) => {
  const renderElement = () => {
    return (
      component.elementName === 'instruction' && (
        <label className='titleTextLbl'>
        {component.fieldName}
          <span className='requiredContainer' style={{ color: 'red' }}>{isRequired(component)}</span>
            <input className='inputComponent' type="text" value={component.fieldData} readOnly/>
        </label>
      )
    );
  };

  return (
    <div className='renderAllElements'>
      {renderElement()}
    </div>
  );
};

export default InstructionsElement;
