import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaTimes } from 'react-icons/fa';
import './CreateCompanyModal.css';
import { crusaders } from '../../../../ClientUpdater/ClientUpdater';

const CreateCompanyModal = ({ isOpen, onClose }) => {
    const [message, setMessage] = useState("");
    const [formData, setFormData] = useState({
        coNo: '',
        coName: '',
        coReg: '',
        coContact: '+27',
        coAdd: '',
        // Add other fields as needed
    });

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData({
    //     ...formData,
    //     [name]: value,
    //     });
    // };
    const handleChange = (e) => {
      const { name, value } = e.target;

      // Check if the field is coContact and does not start with +27
      const updatedValue =
          name === 'coContact' && !value.startsWith('+27') ? `+27${value}` : value;

      setFormData({
          ...formData,
          [name]: updatedValue,
      });
  };

    const handleSubmit = (e) => {
        e.preventDefault();
      
        const sendData = {
            coNo: formData.coNo,
            coName: formData.coName,
            coReg: formData.coReg,
            coContact: formData.coContact,
            coAdd: formData.coAdd,
          
        };
      
        console.log(sendData);
      
        axios.post(`https://worxit.co.za/api/createCompany.php`, sendData)
          .then((res) => {
            if (res.data.success) {
              onClose();  // Close the modal only if the response is true
            } else {
              setMessage(res.data.message);
            }
          })
          .catch((error) => {
            console.error(error);
            setMessage(error.message); // Assuming the error message is meaningful
          });
      };
      

    if (!isOpen) {
        return null;
    }

  return (
    <div className="create-company-modal">
      <div className="create-company-modal-content">
        <h3 className="create-company-title">Create Company</h3>
          <form onSubmit={handleSubmit}>
            <div className='createCompanyCont'>
              <div className='createCompanyTitle'>
                <label className='searchName'>
              Company No:
              </label>
              </div>
              <div className='createCompany'>
                <input
                className='createCompanyInput'
                type="text"
                name="coNo"
                value={formData.coNo}
                onChange={handleChange}
              />
              </div>            
            </div>
            <div className='createCompanyCont'>
              <div className='createCompanyTitle'>
                <label className='searchName'>
              Company Name:
              </label>
              </div>
              <div className='createCompany'>
                <input
                className='createCompanyInput'
                type="text"
                name="coName"
                value={formData.coName}
                onChange={handleChange}
              />
              </div>            
            </div>
            <div className='createCompanyCont'>
              <div className='createCompanyTitle'>
                <label className='searchName'>
             Company Reg:
              </label>
              </div>
              <div className='createCompany'>
                <input
                className='createCompanyInput'
                type="text"
                name="coReg"
                value={formData.coReg}
                onChange={handleChange}
              />
              </div>            
            </div>
            <div className='createCompanyCont'>
              <div className='createCompanyTitle'>
                <label className='searchName'>
              Company Contact:
              </label>
              </div>
              <div className='createCompany'>
                <input
                className='createCompanyInput'
                type="text"
                name="coContact"
                value={formData.coContact}
                onChange={handleChange}
              />
              </div>            
            </div>
            <div className='createCompanyCont'>
              <div className='createCompanyTitle'>
                <label className='searchName'>
              Company Address:
              </label>
              </div>
              <div className='createCompany'>
                <input
                className='createCompanyInput'
                type="text"
                name="coAdd"
                value={formData.coAdd}
                onChange={handleChange}
              />
              </div>            
            </div>
        
             <div className='createCompanySubBtnCont'>
              <button className='createCompanySubBtn' type="submit">Create Company</button>
             </div>
             <div className='messagesCont'>
                {message && <p className='error-message'>{message}</p>}
             </div>
          </form>
          <div onClick={onClose} className="close-cont-create-company-modal">
            <span className="close-create-company-modal-icon">
              <FaTimes />
            </span>
        </div>
      </div>
    </div>
  );
};

export default CreateCompanyModal;
