// UserModal.js
import React from 'react';
import './AccessControlModal.css';

const AccessControlModal = ({ isOpen, onClose }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="accessControl-modal">
      <div className="accessControl-modal-content">
        <h2 className='accessControl-title'>Access Control Modal</h2>
        {/* Add your modal content here */}
        <button onClick={onClose}>Close Modal</button>
      </div>
    </div>
  );
};

export default AccessControlModal;
