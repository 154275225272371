// UserModal.js
import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { FaTimes, FaPencilAlt } from 'react-icons/fa';
import { HiViewList } from "react-icons/hi";
import ReactPaginate from 'react-paginate';
import Loader from '../../../../Loader/Loader';
import CreateSiteModal from '../CreateSitesModal/CreateSitesModal'
import { worxit, crusaders, freedom } from '../../../../ClientUpdater/ClientUpdater';
import {AtomHeaderDataState} from '../../../../RecoilState/AtomHeaderDataState'
import './SitesZonesModal.css';
import { useRecoilValue } from 'recoil';
import DeleteSiteModal from '../DeleteSiteModal/DeleteSiteModal';
import EditSiteModal from '../EditSiteModal/EditSiteModal';


const SitesZonesModal = ({ isOpen, onClose }) => {
  
  const [viewSites, setViewSites]= useState([]); //change this to an atom instead
  const [isLoading, setIsLoading] = useState(false);
  const [searchColumn, setSearchColumn] = useState(""); 
  const [searchKeyword, setSearchKeyword] = useState(""); 
  const [isCreateUserModalOpen, setCreateUserModalOpen] = useState(false);
  const roleValue = useRecoilValue(AtomHeaderDataState)
  const headerData = useRecoilValue(AtomHeaderDataState)
  const coNoValue = headerData[0].coNo;
  const [isDeleteSiteModalOpen, setDeleteSiteModalOpen] = useState(false);
  const [siteToDelete, setSiteToDelete] = useState(null);
  const [isEditSiteModalOpen, setIsEditSiteModalOpen] = useState(false)

  const [selectedSite, setSelectedSite] = useState(null);

  // const [message, setMessage] = useState("");
    
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const usersPerPage = rowsPerPage;
  const [pageNumber, setPageNumber] = useState(0);

  const SitesTable = async () => {
    try {
      if(coNoValue === 'cc000'){
        const response = await axios.get(`https://worxit.co.za/api/${worxit}/view/viewSites.php`);
        const newData = response.data.records;
        setViewSites(newData);
        setIsLoading(false);
        console.log('New Data cc000:', newData);
      }
      // else if(coNoValue === 'cc004'){
      //   const response = await axios.get(`https://worxit.co.za/api/${crusaders}/view/viewSites.php`);
      //   const newData = response.data.records;
      //   setViewSites(newData);
      //   setIsLoading(false);
      //   console.log('New Data cc004:', newData);
      // }
      else if(coNoValue === 'cc0018'){
        const response = await axios.get(`https://worxit.co.za/api/${freedom}/view/viewSites.php`);
        const newData = response.data.records;
        setViewSites(newData);
        setIsLoading(false);
        console.log('New Data cc0018:', newData);
      }
      
      
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data: ", error);
    }
  };
    
useEffect(() => {
    SitesTable(); // Initial call
    const intervalId = setInterval(SitesTable, 3000);
    return () => clearInterval(intervalId);
  }, [setViewSites]); // Empty dependency array to run this effect only once
  
  console.log('viewSites: ', viewSites)
  // });
  const filteredSearch = viewSites.filter((user) => {
    // Check if the user matches the search criteria
    const matchesSearch =
      searchColumn !== ""
        ? user[searchColumn].toLowerCase().includes(searchKeyword.toLowerCase())
        : Object.values(user).some((value) =>
            value.toLowerCase().includes(searchKeyword.toLowerCase())
          );
  
    // // Check if the user matches the role criteria based on the logged-in user's role
    // const matchesRole =
    //   roleValue[0]?.role === 3 ? user.role === '3' : true;
  
      console.log('user:', user);
  console.log('matchesSearch:', matchesSearch);
  //console.log('matchesRole:', matchesRole);
  
    // Combine the search and role criteria
    return matchesSearch 
    //&& matchesRole;
  });
  
    
  
  const deleteForm = (id) => {
    setSiteToDelete(id);
    setDeleteSiteModalOpen(true);
  };

  const confirmDelete = async () => {
    try {
      if(coNoValue === 'cc000'){
        await axios.delete(`https://worxit.co.za/api/${worxit}/delete/deleteSite.php?id=${siteToDelete}`);
        setDeleteSiteModalOpen(false);
      }
      // else if(coNoValue === 'cc004'){
      //   await axios.delete(`https://worxit.co.za/api/${crusaders}/delete/deleteSite.php?id=${siteToDelete}`);
      //   setDeleteSiteModalOpen(false);
      // }
      else if(coNoValue === 'cc0018'){
        await axios.delete(`https://worxit.co.za/api/${freedom}/delete/deleteSite.php?id=${siteToDelete}`);
        setDeleteSiteModalOpen(false);
      }
    
    } catch (error) {
      console.error("Error deleting form: ", error);
    }
  };

  const cancelDelete = () => {
    setSiteToDelete(null);
    setDeleteSiteModalOpen(false);
  };

  const editForm = (site) => {
    setSelectedSite(site);
    setIsEditSiteModalOpen(true);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="SitesZones-modal">
      <div className="SitesZones-modal-content">
        <div className='modalHeader'>
          <div className='header-title-cont'>
            <h3 className='SitesZones-title'>SitesZones</h3>
          </div>
          {roleValue[0]?.role === 0 || roleValue[0]?.role === 1 || roleValue[0]?.role === 2 || roleValue[0]?.role === 3 ? (
            <div className='defaultBtnCont'> 
            <span className='defaultBtn' onClick={() => setCreateUserModalOpen(true)}>Add Site</span>
            </div>
            ) : null
          }
          <div onClick={onClose} className='closeContAllModals' >
              <span className='closeAllModalIcon'>
              <FaTimes />
            </span>
          </div>
        </div>
        <div className="row filter">
          <div className="column1AllUsers">
            <label className='searchByTitle'>Search By:</label>
          </div>
          <div className="column2AllUsers">
            <select
              className="searchInput"
              value={searchColumn}
              onChange={(e) => setSearchColumn(e.target.value)}
            >
              <option value="">All</option>
              <option value="site">Sites</option>
              <option value="siteAddress">Site Address</option>
            </select>
          </div>
        
          <div className="column3AllUsers">
            <input
              type="text"
              className="searchInput"
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
            />
          </div>
        </div>
        
        
        {/* <h7>Latest 5 Orders Completed...</h7> */}
        <table className="usersTbl">
            <thead>
                <tr>
                    <th>Sites</th>
                    <th>Site Address</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
              {isLoading ? (
                  <Loader />
                ) : filteredSearch.length > 0 ? (
                  // .sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime))
                  filteredSearch
                    .slice(pageNumber * usersPerPage, (pageNumber + 1) * usersPerPage)
                    .map((viewSites, index) => (
                      <tr key={index}>
                        <td>{viewSites.site}</td>
                        <td>{viewSites.siteAddress}</td>
                        <td>
                        <div className='editDeleteIcons'>
                        {/* <div className='viewRow'>
                           <span title='View Site' className='viewRowIcon' >
                           <HiViewList />
                          </span>
                        </div> */}
                        <div className='editRow'>
                           <span title='Edit Site' className='editRowIcon' onClick={() => editForm(viewSites)}>
                            <FaPencilAlt />
                          </span>
                        </div>
                        <div className='deleteRow'>
                          <span title='Delete Site' className='deleteRowIcon' onClick={() => deleteForm(viewSites.id)}>
                            <FaTimes />
                          </span>
                        </div>
                      </div>
                          {/* <Link to={`/editAllUsers/${viewUsers.id}`}><EditIcon/>
                          </Link>  */}
                          {/* <button onClick={() => deleteCompany(viewUsers.id)}>< DeleteForeverIcon sx={{ color: red[900] }}/></button> */}
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td colSpan="6">No Records Found</td>
                  </tr>
                )}

                </tbody>
            </table>   
            <div className="rows-per-page">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={Math.ceil(filteredSearch.length / usersPerPage)}
                onPageChange={({ selected }) => setPageNumber(selected)}
                containerClassName={"pagination"}
                previousLinkClassName={"pagination__link"}
                nextLinkClassName={"pagination__link"}
                disabledClassName={"pagination__link--disabled"}
                activeClassName={"pagination__link--active"}
              />
            </div>
            
          {isCreateUserModalOpen && (
          <CreateSiteModal 
            isOpen={isCreateUserModalOpen} 
            onClose={() => setCreateUserModalOpen(false)} />
        )}
         <DeleteSiteModal
        isOpen={isDeleteSiteModalOpen}
        onConfirm={confirmDelete}
        onCancel={cancelDelete}
      />
      {selectedSite && (
      <EditSiteModal
        isOpen={isEditSiteModalOpen}
        onClose={() => setIsEditSiteModalOpen(false)}
        site={selectedSite}
        
      />
    )}
      </div>
    </div>
  );
};

export default SitesZonesModal;
