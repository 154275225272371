import React from 'react';
import '../../FormBuilder.css';

const SignatureElement = ({ component, isRequired }) => {
  const renderElement = () => {
    return (
      component.elementName === 'signature' && (
        <label className='titleTextLbl'>
            {component.fieldName || "Signature"}
            <span className='requiredContainer' style={{ color: 'red' }}>{isRequired(component)}</span>
        </label>
      )
    );
  };

  return (
    <div className='renderAllElements'>
      {renderElement()}
    </div>
  );
};

export default SignatureElement;
