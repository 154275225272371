// UserModal.js
import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { FaTimes } from 'react-icons/fa';
import ReactPaginate from 'react-paginate';
import Loader from '../../../../Loader/Loader'
import { crusaders } from '../../../../ClientUpdater/ClientUpdater';
import CreateDeviceModal from '../CreateDeviceModal/CreateDeviceModal';
import { AtomHeaderDataState } from '../../../../RecoilState/AtomHeaderDataState';
import { useRecoilValue } from 'recoil';
import './DevicesModal.css';


const DevicesModal = ({ isOpen, onClose }) => {
  
  const [viewDevices, setViewDevices]= useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchColumn, setSearchColumn] = useState(""); 
  const [searchKeyword, setSearchKeyword] = useState(""); 
  const [isCreateDeviceModalOpen, setCreateDeviceModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const roleValue = useRecoilValue(AtomHeaderDataState)
    
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const usersPerPage = rowsPerPage;
  const [pageNumber, setPageNumber] = useState(0);

  const devicesTable = async () => {
    try {
      const response = await axios.get(`https://worxit.co.za/api/viewDevices.php`);
      const newData = response.data.records;
      setViewDevices(newData);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data: ", error);
    }
  };
  
useEffect(() => {
  devicesTable(); // Initial call
  const intervalId = setInterval(devicesTable, 3000);
  return () => clearInterval(intervalId);
}, [setViewDevices]); // Empty dependency array to run this effect only once
  
  const filteredSearch = viewDevices.filter((user) => {
    if (searchColumn !== "") {
      return user[searchColumn].toLowerCase().includes(searchKeyword.toLowerCase());
    }
    // If "All" is selected, check if any column contains the search keyword
    return Object.values(user).some((value) =>
      value.toLowerCase().includes(searchKeyword.toLowerCase())
    );
  });
    
  useEffect(() => {
    if (filteredSearch.length === 0 && searchKeyword !== "") {
      setMessage("No Match Found");
    } else {
      setMessage("");
    }
  }, [filteredSearch, searchColumn, searchKeyword]);


    const deleteDevices = (id) =>{
      axios.delete("https://worxit.co.za/api/zamatrack/delete/deleteUser.php", {data: {id: id}})
      .then(()=>{
        devicesTable();
      }).catch(()=>{
          alert("Error, Cannot Delete User!");
      })
  }

  if (!isOpen) {
    return null;
  }

  return (
    <div className="devices-modal">
      <div className="devices-modal-content">
        <div className='modalHeader'>
          <div className='header-title-cont'>
            <h3 className='devices-title'>Devices</h3>
          </div>
          {roleValue[0]?.role === 1 || roleValue[0]?.role === 2 ? (
            <div className='defaultBtnCont'>
              <span className='defaultBtn' onClick={() => setCreateDeviceModalOpen(true)}>Add Device</span>
            </div>
            ) : null
          }
          <div onClick={onClose} className='closeContAllModals' >
            <span className='closeAllModalIcon'>
            <FaTimes />
          </span>
        </div>
        </div>
        <div className="row filter">
          <div className="column1AllUsers">
            <label className='searchByTitle'>Search By:</label>
          </div>
          <div className="column2AllUsers">
            <select
              className="searchInput"
              value={searchColumn}
              onChange={(e) => setSearchColumn(e.target.value)}
            >
              <option value="">All</option>
              <option value="deviceId">Device ID</option>
              <option value="coNo">Company No</option>
            </select>
          </div>
        
          <div className="column3AllUsers">
            <input
              type="text"
              className="searchInput"
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
            />
          </div>
        </div>
         

        {/* <h7>Latest 5 Orders Completed...</h7> */}
        <table className="usersTbl">
            <thead>
                <tr>
                    <th>Device Id</th>
                    <th>Device Name</th>
                    <th>Company No</th>
                    <th>Site</th>
                    <th>Status</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
              {isLoading ? (
                  <Loader />
                ) : filteredSearch.length > 0 ? (
                  // .sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime))
                  filteredSearch
                    .slice(pageNumber * usersPerPage, (pageNumber + 1) * usersPerPage)
                    .map((viewUsers, index) => (
                      <tr key={index}>
                        <td>{viewUsers.deviceId}</td>
                        <td>{viewUsers.deviceName}</td>
                        <td>{viewUsers.coNo}</td>
                        <td>{viewUsers.site}</td>
                        <td>
                          <div
                            className={`statusCircle ${
                              viewUsers.status === '1' ? 'green' : 'red'
                            }`}
                          ></div>
                        </td>
                        <td>
                          {/* <Link to={`/editAllUsers/${viewUsers.id}`}><EditIcon/>
                          </Link>  */}
                          {/* <button onClick={() => deleteCompany(viewUsers.id)}>< DeleteForeverIcon sx={{ color: red[900] }}/></button> */}
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td colSpan="6">No Records Found</td>
                  </tr>
                )}

                </tbody>
            </table>   
            <div className="rows-per-page">
              {/* <label className="lblRowCo">Rows per page:</label> */}
              {/* <select className="pageRowsCo" value={rowsPerPage} onChange={handleRowsPerPageChange}>
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
              </select> */}
            

            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={Math.ceil(filteredSearch.length / usersPerPage)}
              onPageChange={({ selected }) => setPageNumber(selected)}
              containerClassName={"pagination"}
              previousLinkClassName={"pagination__link"}
              nextLinkClassName={"pagination__link"}
              disabledClassName={"pagination__link--disabled"}
              activeClassName={"pagination__link--active"}
            />
            </div>
            {message && <p className="NoMatchFoundMsg">{message}</p>} 
        
        {isCreateDeviceModalOpen && (
          <CreateDeviceModal 
            isOpen={isCreateDeviceModalOpen} 
            onClose={() => setCreateDeviceModalOpen(false)} />
        )}
      </div>
    </div>
  );
};

export default DevicesModal;
