import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaTimes, FaPencilAlt } from 'react-icons/fa';
import { MdSaveAlt } from "react-icons/md";
import './ViewUserDetailsModal.css';
import { crusaders } from '../../../../ClientUpdater/ClientUpdater';
import ViewUserSitesModal from '../ViewUserSitesModal/ViewUserSitesModal';

const ViewUserDetailsModal = ({ isOpen, onClose, user }) => {
    const [editableField, setEditableField] = useState(null);
    const [editedSite, setEditedUserDetails] = useState({ ...user });
    const [isViewUserSitesModalOpen, setisViewUserSitesModalOpen] = useState(false);
    const [selectedCoNo, setSelectedCoNo] = useState('');
    const [selectedZones, setSelectedZones] = useState('');
    const [message, setMessage] = useState("");

    console.log('user: ', user)
    const [formData, setFormData] = useState({
        username: '',
        role: '',
        password: '',
        confirmPassword: '',
        empNo: ''
        // Add other fields as needed
    });
  

    const handleUpdate=(e)=>{
      e.preventDefault();
  
      // if (data.password !== data.confirmPassword) {
      //   setMessage("Passwords do not match.");
      // return;
      // }
  
     const sendData={
        username:formData.username,
        password:formData.password,
        confirmPassword:formData.confirmPassword,
        empNo:formData.empNo,
        role:formData.role,
        coNo:selectedCoNo,
        zone: selectedZones

      }
      console.log(sendData)
      axios.post(`https://worxit.co.za/api/${crusaders}/create/createUsers.php`,sendData)
      .then((res)=> {
        if (res.data.success) {
          onClose();  // Close the modal only if the response is true
          setFormData({ deviceId: "", coNo: "" });
        } else {
          setMessage(res.data.message);
        }
        // setMessageSuc(res.data.messageSuc);
        // setMessageError("");
        // setFormData({username:"", password:"",confirmPassword:"",role:"",coNo:"", zone:""})
      })
      .catch((res) => {
        console.error(res);
        setMessage(res.data.message);
       
      });
    }

    const handleEditClick = (fieldName) => {
      setEditableField(fieldName);
    };

    const handleUpdateClick = () => {
      // After updating, switch back to view mode
     setEditableField(null);
   };

    const handleInputChange = (e) => {
      // Update the editedSite state when input values change
      const { name, value } = e.target;
      setEditedUserDetails((prevSite) => ({
        ...prevSite,
        [name]: value,
      }));
    };
  
    useEffect(() => {
      // Update editedSite when the site prop changes
      setEditedUserDetails({ ...user });
    }, [user]);
  

    if (!isOpen) {
        return null;
    }

  return (
    <div className="create-user-details-modal">
      <div className="create-user-details-modal-content">
        <div className="modalHeader">
          <div className="header-title-cont"> 
            <h3 className="create-user-details-title">User Details</h3>
          </div>
          <div className='defaultBtnCont'>
            <span className='defaultBtn'>Update</span>
          </div>
          <div onClick={onClose} className="closeContAllModals">
            <span className="closeAllModalIcon">
              <FaTimes />
            </span>
          </div>
        </div>
        
        <div className="editable-field">
        <div className='editable-field-col1'>
          <span>Username:</span>
        </div>
          
          {editableField === 'username' ? (
            <div className='editable-field-col2'>
              <div className='editable-field-col2-col1'>
                 <input
                type="text"
                name="username"
                value={editedSite.username}
                onChange={handleInputChange}
              />
              </div>
             <div title='Save' className='editable-field-col2-col2'>
              <MdSaveAlt
                className="update-icon"
                onClick={handleUpdateClick}
              />
             </div>
              
            </div>
          ) : (
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{editedSite.username}</span>
              </div>
              <div title='Edit' className='editable-field-col3-col2'>
                <FaPencilAlt
                  className="edit-icon"
                  onClick={() => handleEditClick('username')}
                />
              </div>
             
            </div>
          )}
        </div>
        <div className="editable-field">
        <div className='editable-field-col1'>
          <span>Role:</span>
        </div>
          
          {editableField === 'role' ? (
            <div className='editable-field-col2'>
              <div className='editable-field-col2-col1'>
                 <input
                type="text"
                name="role"
                value={editedSite.role}
                onChange={handleInputChange}
              />
              </div>
             <div title='Save' className='editable-field-col2-col2'>
              <MdSaveAlt
                className="update-icon"
                onClick={handleUpdateClick}
              />
             </div>
              
            </div>
          ) : (
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{editedSite.role}</span>
              </div>
              <div title='Edit' className='editable-field-col3-col2'>
                <FaPencilAlt
                  className="edit-icon"
                  onClick={() => handleEditClick('role')}
                />
              </div>
             
            </div>
          )}
        </div>
        <div className="editable-field">
        <div className='editable-field-col1'>
          <span>Name:</span>
        </div>
          
          {editableField === 'name' ? (
            <div className='editable-field-col2'>
              <div className='editable-field-col2-col1'>
                 <input
                type="text"
                name="name"
                value={editedSite.name}
                onChange={handleInputChange}
              />
              </div>
             <div title='Save' className='editable-field-col2-col2'>
              <MdSaveAlt
                className="update-icon"
                onClick={handleUpdateClick}
              />
             </div>
              
            </div>
          ) : (
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{editedSite.name}</span>
              </div>
              <div title='Edit' className='editable-field-col3-col2'>
                <FaPencilAlt
                  className="edit-icon"
                  onClick={() => handleEditClick('name')}
                />
              </div>
             
            </div>
          )}
        </div>
        <div className="editable-field">
        <div className='editable-field-col1'>
          <span>Surname:</span>
        </div>
          
          {editableField === 'userSurname' ? (
            <div className='editable-field-col2'>
              <div className='editable-field-col2-col1'>
                 <input
                type="text"
                name="userSurname"
                value={editedSite.userSurname}
                onChange={handleInputChange}
              />
              </div>
             <div title='Save' className='editable-field-col2-col2'>
              <MdSaveAlt
                className="update-icon"
                onClick={handleUpdateClick}
              />
             </div>
              
            </div>
          ) : (
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{editedSite.userSurname}</span>
              </div>
              <div title='Edit' className='editable-field-col3-col2'>
                <FaPencilAlt
                  className="edit-icon"
                  onClick={() => handleEditClick('userSurname')}
                />
              </div>
             
            </div>
          )}
        </div>
        <div className="editable-field">
        <div className='editable-field-col1'>
          <span>identity No:</span>
        </div>
          
          {editableField === 'identityNo' ? (
            <div className='editable-field-col2'>
              <div className='editable-field-col2-col1'>
                 <input
                type="text"
                name="identityNo"
                value={editedSite.identityNo}
                onChange={handleInputChange}
              />
              </div>
             <div title='Save' className='editable-field-col2-col2'>
              <MdSaveAlt
                className="update-icon"
                onClick={handleUpdateClick}
              />
             </div>
              
            </div>
          ) : (
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{editedSite.identityNo}</span>
              </div>
              <div title='Edit' className='editable-field-col3-col2'>
                <FaPencilAlt
                  className="edit-icon"
                  onClick={() => handleEditClick('identityNo')}
                />
              </div>
             
            </div>
          )}
        </div>
        <div className="editable-field">
        <div className='editable-field-col1'>
          <span>Company No:</span>
        </div>
          
          {editableField === 'coNo' ? (
            <div className='editable-field-col2'>
              <div className='editable-field-col2-col1'>
                 <input
                type="text"
                name="coNo"
                value={editedSite.coNo}
                onChange={handleInputChange}
              />
              </div>
             <div title='Save' className='editable-field-col2-col2'>
              <MdSaveAlt
                className="update-icon"
                onClick={handleUpdateClick}
              />
             </div>
              
            </div>
          ) : (
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{editedSite.coNo}</span>
              </div>
              <div title='Edit' className='editable-field-col3-col2'>
                <FaPencilAlt
                  className="edit-icon"
                  onClick={() => handleEditClick('coNo')}
                />
              </div>
             
            </div>
          )}
        </div>
        <div className="editable-field">
        <div className='editable-field-col1'>
          <span>Employee No:</span>
        </div>
          
          {editableField === 'empNo' ? (
            <div className='editable-field-col2'>
              <div className='editable-field-col2-col1'>
                 <input
                type="text"
                name="empNo"
                value={editedSite.empNo}
                onChange={handleInputChange}
              />
              </div>
             <div title='Save' className='editable-field-col2-col2'>
              <MdSaveAlt
                className="update-icon"
                onClick={handleUpdateClick}
              />
             </div>
              
            </div>
          ) : (
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{editedSite.empNo}</span>
              </div>
              <div title='Edit' className='editable-field-col3-col2'>
                <FaPencilAlt
                  className="edit-icon"
                  onClick={() => handleEditClick('empNo')}
                />
              </div>
             
            </div>
          )}
        </div>
        <div className="editable-field">
        <div className='editable-field-col1'>
          <span>Contact No:</span>
        </div>
          
          {editableField === 'userContact' ? (
            <div className='editable-field-col2'>
              <div className='editable-field-col2-col1'>
                 <input
                type="text"
                name="userContact"
                value={editedSite.userContact}
                onChange={handleInputChange}
              />
              </div>
             <div title='Save' className='editable-field-col2-col2'>
              <MdSaveAlt
                className="update-icon"
                onClick={handleUpdateClick}
              />
             </div>
              
            </div>
          ) : (
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{editedSite.userContact}</span>
              </div>
              <div title='Edit' className='editable-field-col3-col2'>
                <FaPencilAlt
                  className="edit-icon"
                  onClick={() => handleEditClick('userContact')}
                />
              </div>
             
            </div>
          )}
        </div>
        <div className="editable-field">
        <div className='editable-field-col1'>
          <span>Email:</span>
        </div>
          
          {editableField === 'userEmail' ? (
            <div className='editable-field-col2'>
              <div className='editable-field-col2-col1'>
                 <input
                type="text"
                name="userEmail"
                value={editedSite.userEmail}
                onChange={handleInputChange}
              />
              </div>
             <div title='Save' className='editable-field-col2-col2'>
              <MdSaveAlt
                className="update-icon"
                onClick={handleUpdateClick}
              />
             </div>
              
            </div>
          ) : (
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{editedSite.userEmail}</span>
              </div>
              <div title='Edit' className='editable-field-col3-col2'>
                <FaPencilAlt
                  className="edit-icon"
                  onClick={() => handleEditClick('userEmail')}
                />
              </div>
             
            </div>
          )}
        </div>
        <div className="editable-field">
        <div className='editable-field-col1'>
          <span>Address:</span>
        </div>
          
          {editableField === 'userAddress' ? (
            <div className='editable-field-col2'>
              <div className='editable-field-col2-col1'>
                 <input
                type="text"
                name="userAddress"
                value={editedSite.userAddress}
                onChange={handleInputChange}
              />
              </div>
             <div title='Save' className='editable-field-col2-col2'>
              <MdSaveAlt
                className="update-icon"
                onClick={handleUpdateClick}
              />
             </div>
              
            </div>
          ) : (
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{editedSite.userAddress}</span>
              </div>
              <div title='Edit' className='editable-field-col3-col2'>
                <FaPencilAlt
                  className="edit-icon"
                  onClick={() => handleEditClick('userAddress')}
                />
              </div>
             
            </div>
          )}
        </div>
        <div className="editable-field">
        <div className='editable-field-col1'>
          <span className='defaultBtn' onClick={() => setisViewUserSitesModalOpen(true)}>Sites</span>
          </div>
          
        </div>
        {isViewUserSitesModalOpen && (
          <ViewUserSitesModal 
            isOpen={isViewUserSitesModalOpen} 
            onClose={() => setisViewUserSitesModalOpen(false)} 
            user={user}/>
        )}
          {/* <form onSubmit={handleSubmit}>
             <div className='createUserSubBtnCont'>
              <button className='createUserSubBtn' type="submit">Create User</button>
             </div>
             <div className='messagesCont'>
                {message && <p className='error-message'>{message}</p>}
             </div>
          </form> */}
         
      </div>
    </div>
  );
};

export default ViewUserDetailsModal;
