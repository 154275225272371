import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import { CgAdd } from 'react-icons/cg';

import './EditModal.css';

const EditModal = ({ isOpen, onRequestClose, component, onSave, onUpdate, showSelectedForm, selectedForm }) => {
  const [editedLabel, setEditedLabel] = useState(component.fieldName || '');
  // const [isEditedRequired, setIsEditedRequired] = useState(component.required || false);
  const [isEditedRequired, setIsEditedRequired] = useState(component.required);


  const [editedDropdownOptions, setEditedDropdownOptions] = useState(component.dropdownOptions || [])
  const [instructionsDetails, setInstructionsDetails] = useState(component.fieldData || '');

  // useEffect(() => {
  //   // Update isChecked whenever the component prop changes
  //   if(component.required === '1'){
  //     console.log('Required: ', true )
  //     setIsEditedRequired('1');
  //   }else{
  //     console.log('Required: ', false )
  //     setIsEditedRequired('')
  //   }
    
  // }, [component]);

  useEffect(() => {
    if (!showSelectedForm) {
      if (component.elementName === 'dropdown' && !component.dropdownOptions) {
        setEditedDropdownOptions(['Option 1', 'Option 2', 'Option 3']);
      } 

    } else {
      if (component.elementName === 'dropdown' && component.elementId === 'edrop') {
        // Add a check for component.fieldData before using map
        if (component.fieldData && Array.isArray(component.fieldData)) {
          setEditedDropdownOptions(component.fieldData.map((option) => option));
        } else {
          // Handle the case where component.fieldData is not an array or is undefined
          setEditedDropdownOptions(['Option 1', 'Option 2', 'Option 3']);
        }
      }
    }
  }, [component, showSelectedForm]);
  

  const handleSave = () => {

    let fieldData;

  if (component.elementName === 'dropdown') {
    fieldData = editedDropdownOptions;
  } else {
    // Assume it's 'instructions'
    fieldData = instructionsDetails;
  }

    const editProperties = {
      fieldName: editedLabel,
      required: isEditedRequired,
      // required: isEditedRequired,
      fieldData: fieldData

    };
    onSave(editProperties);
  };

  const handleUpdate = () => {

    let fieldData;

    if (component.elementName === 'dropdown') {
      fieldData = editedDropdownOptions;
    } else {
      // Assume it's 'instructions'
      fieldData = instructionsDetails;
    }

    const updatedProperties = {
      fieldName: editedLabel,
      required: isEditedRequired,
      // required: isEditedRequired,
      fieldData: fieldData
    };
  
    onUpdate(updatedProperties);
  };

  if (!isOpen) {
    return null;
  }

  const handleOptionChange = (index, value) => {
    const editedOptions = [...editedDropdownOptions];
    editedOptions[index] = value;
    setEditedDropdownOptions(editedOptions);
  };

  const removeOption = (index) => {
    const editedOptions = editedDropdownOptions.filter((option, i) => i !== index);
    setEditedDropdownOptions(editedOptions);
  };

  const addOption = () => {
    setEditedDropdownOptions([...editedDropdownOptions, 'New Option']);
  };

  return (
    <div className={`editModal ${isOpen ? 'editModalOpen' : ''}`}>
      <div className='editModalContent'>
      {/* <div className='checkboxContainer'>
          <label className='labelTitle'>
            required:
          </label>
                                 
          <input
                type="checkbox"
                checked={isEditedRequired === '1'}
                onChange={() => setIsEditedRequired((prevValue) => (prevValue === '1' ? '0' : '1'))}

              />
        </div> */}
         <div className='requireContainer'>
          <div className='column1Cont'>
            <label className='labelTitle'>
              Required:
            </label>
          </div>
          <div className='column2Cont'>
            <div className='requireContainer'>
              <div className='column2Cont'>
                <input
                      type="checkbox"
                      checked={isEditedRequired === '1'}
                      onChange={() => setIsEditedRequired((prevValue) => (prevValue === '1' ? '0' : '1'))}
                    />
              </div>
            </div>

           
          </div>
        </div>

        <div className='labelContainer'>
          <div className='column1Cont'>
            <label className='labelTitle'>
              Label:
            </label>
          </div>
          <div className='column2Cont'>
           
              <input
                className='inputComponent'
                type="text"
                value={editedLabel}
                onChange={(e) => setEditedLabel(e.target.value)}
              />
           
            
          </div>
        </div>

        {component.elementName === "instruction" ? (
          <div className='labelContainer'>
            <div className='column1Cont'>
              <label className='labelTitle'>
                Instruction:
              </label>
            </div>
            <div className='column2Cont'>
                <input
                  className='inputComponent'
                  type="text"
                  value={instructionsDetails}
                  onChange={(e) => setInstructionsDetails(e.target.value)}
                />
            </div>
          </div>
        ) : null
      }
       

      {component.elementName === 'dropdown' ? (
          <div className='dropdownCont'>
            <div className='column1ContDropdown'>
              <label className='labelTitle'>Options:</label>
            </div>
            <div className="column2ContDropdown">
              {editedDropdownOptions.map((option, index) => (
                <div className='optionsCont' key={index}>
                  <input
                    className='inputComponent'
                    type="text"
                    value={option}
                    onChange={(e) => handleOptionChange(index, e.target.value)}
                  />
                  <span className='deleteDropdownOptions' onClick={() => removeOption(index)}><FaTimes/></span>
                </div>
              ))}
               <span className='addOptions' onClick={addOption}>Add Options <CgAdd style={{color: "green", paddingLeft: "12px"}}/></span>
            </div>
          </div>
        ) : null}

        {!selectedForm ? (
          <div className='saveCont'>
            <button className='saveEditBtn' onClick={handleSave}>Save</button>
          </div>
        ) : (
          <div className='saveCont'>
            <button className='saveEditBtn' onClick={handleUpdate}>Update</button>
          </div>
        )}      
        <div className="closeCont">
          <span onClick={onRequestClose}>
            Close
          </span>
        </div>
      </div>
    </div>
  );
};

export default EditModal;
