// src/components/DraggableComponents.js
import React from 'react';
import { useDrag } from 'react-dnd';
import './FormBuilder.css';
import Tooltip from '@mui/material/Tooltip';
import { RxInput } from "react-icons/rx";
import { MdTitle } from "react-icons/md";
import { BsUpcScan } from "react-icons/bs";
import { FaUser } from "react-icons/fa";
import { IoToggle } from "react-icons/io5";
import { MdGpsFixed } from "react-icons/md";
import { IoCamera } from "react-icons/io5";
import { IoCameraReverse } from "react-icons/io5";
import { IoVideocam } from "react-icons/io5";
import { FaFileSignature } from "react-icons/fa6";
import { TbCalendarTime } from "react-icons/tb";
import { PiTextAlignLeftBold } from "react-icons/pi";
import { FaRegCalendarAlt } from "react-icons/fa";
import { RxDropdownMenu } from "react-icons/rx";
import { IoLocation } from "react-icons/io5";
import { SlBookOpen } from "react-icons/sl";
import { FaCar } from "react-icons/fa6";



const DraggableComponent = ({ type, label, elementName, title, icon }) => {
  const [, drag] = useDrag(() => ({
    type,
    elementName,
    item: { type, elementName, label },
    
  }));

  return (
    <Tooltip title={`${title}`} placement='left' arrow>
      <div ref={drag} className="draggable-component">
        <div className='componentIcon&Title'>
          <div className='componentIconCont'>
            {icon}
          </div>
          <div>
            {label}
          </div>
        </div>
       
        
      </div>
    </Tooltip>
  );
};

const DraggableComponents = () => {
  return (
    
   
    <div className="draggable-components">
         
      <DraggableComponent icon={<RxInput color='#484848'/>} type="input" label="Short Text Input" elementName="input" title="Single line text"/>
      <DraggableComponent icon={<SlBookOpen color='#484848'/> } type="input" label="Instruction" elementName="instruction" title="Instruction"/>
      <DraggableComponent icon={<MdTitle color='#484848'/>} type="label" label="Sub Title" elementName="subTitle" title="Heading"/>
      <DraggableComponent icon={<BsUpcScan color='#484848'/>} type="label" label="QR Single Scan" elementName="qrSingleScan" title="QR Single Scan"/>
      <DraggableComponent icon={<BsUpcScan color='#484848'/>} type="label" label="QR Multi Scan" elementName="qrMultiScan" title="QR Multi Scan"/>
      <DraggableComponent icon={<BsUpcScan color='#484848'/>} type="label" label="2D Single Scan" elementName="2dSingleScan" title="2D Single Scan"/>
      <DraggableComponent icon={<BsUpcScan color='#484848'/>} type="label" label="2D Multi Scan" elementName="2dMultiScan" title="2D Multi Scan"/>
      <DraggableComponent icon={<FaCar color='#484848'/>} type="label" label="Scan Vehicle Reg" elementName="scanVehicleReg" title="Scan Vehicle Reg"/>
      <DraggableComponent icon={<FaCar color='#484848'/>} type="label" label="2D Scan Vehicle Reg" elementName="2dscanVehicleReg" title="2D Scan Vehicle Reg"/>
      <DraggableComponent icon={<FaUser color='#484848'/>} type="label" label="LoggedUser" elementName="loggedUser" title="Uses the logged in users name"/>
      <DraggableComponent icon={<IoToggle color='#484848'/>} type="label" label="Yes/No Questions (Default No)" elementName="yes/noQuestions" title="Yes or No Questions (Default No)"/>
      <DraggableComponent icon={<MdGpsFixed color='#484848'/>} type="label" label="Fetch GPS" elementName="fetchGps" title="Gets the coordinates"/>
      <DraggableComponent icon={<IoCamera color='#484848'/>} type="label" label="Camera" elementName="camera" title="Takes Photos"/>
      <DraggableComponent icon={<IoVideocam color='#484848'/>} type="label" label="Video" elementName="video" title="Takes Videos"/>
      <DraggableComponent icon={<FaFileSignature color='#484848'/>} type="label" label="Signature" elementName="signature" title="Signatures"/>
      <DraggableComponent icon={<IoLocation color='#484848'/>} type="label" label="Site" elementName="site" title="This is the site"/>
      <DraggableComponent icon={<TbCalendarTime color='#484848'/>} type="label" label="Date/Time" elementName="dateTime" title="Automatically gets current date and time"/>
      <DraggableComponent icon={<IoCameraReverse color='#484848'/>} type="label" label="Selfie Camera" elementName="selfieCamera" title="Takes selfie camera"/>
      <DraggableComponent icon={<PiTextAlignLeftBold color='#484848'/>} type="textarea" label="Long Text Input" elementName="textarea" title="Multiple line text"/>
      <DraggableComponent icon={<FaRegCalendarAlt color='#484848'/>} type="date" label="Date Picker" elementName="datePicker" title="Date Picker"/>
      <DraggableComponent icon={<RxDropdownMenu color='#484848'/>} type="dropdown" label="Dropdown" elementName="dropdown" title="Dropdown with customizable options"/>

      {/* Add more draggable components */}
    </div>
    
  );
};

export default DraggableComponents;
