import React from 'react';
import '../../FormBuilder.css';

const ScanVehicleRegElement = ({ component, isRequired }) => {
  const renderElement = () => {
    return (
      component.elementName === 'scanVehicleReg' && (
        <label className='titleTextLbl'>
            {component.fieldName || "Scan Vehicle Reg"}
            <span className='requiredContainer' style={{ color: 'red' }}>{isRequired(component)}</span>
      </label>
      )
    );
  };

  return (
    <div className='renderAllElements'>
      {renderElement()}
    </div>
  );
};

export default ScanVehicleRegElement;
