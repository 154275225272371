// App.js
import React, { useState } from 'react';
import AppRoutes from './AppRoutes';
import './App.css'; // You will create this CSS file

const App = () => {
 
  return (
    <div className="App">
      <AppRoutes/>
    </div>
  );
};

export default App;
