// ViewUserZonesModal.js
import React, { useState, useEffect } from 'react';
import { FaTimes, FaPencilAlt } from 'react-icons/fa';
import { MdSaveAlt } from "react-icons/md";
import Loader from '../../../../Loader/Loader';
import axios from 'axios';
import { worxit, crusaders, freedom } from '../../../../ClientUpdater/ClientUpdater';
import './ViewUserZonesModal.css';
import ReactPaginate from 'react-paginate';
import { AtomHeaderDataState } from '../../../../RecoilState/AtomHeaderDataState';
import { useRecoilValue } from 'recoil';
import Checkbox from '@mui/material/Checkbox';
// ... (import statements)

const ViewUserZonesModal = ({ isOpen, onClose, site, user }) => {
  const headerData = useRecoilValue(AtomHeaderDataState)
  const coNoValue = headerData[0].coNo;
  const [viewSelectedZone, setViewSelectedZone] = useState([])
  const [editableField, setEditableField] = useState(null);
  const [editedSite, setEditedSite] = useState({ ...site });
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [filteredZones, setFilteredZones] = useState([]);
  const [selectedZone, setSelectedZone] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const usersPerPage = rowsPerPage;
  const [pageNumber, setPageNumber] = useState(0);
  const [checkedZones, setCheckedZones] = useState([...user.zone]); // Set initial state

  const ZonesTable = async () => {
    try {
      if(coNoValue === 'cc000'){
        const response = await axios.get(`https://worxit.co.za/api/${worxit}/view/viewZones.php`);
        const newData = response.data.records;
        setViewSelectedZone(newData);
        setIsLoading(false);
        
      }
      // else if(coNoValue === 'cc004'){
      //   const response = await axios.get(`https://worxit.co.za/api/${crusaders}/view/viewZones.php`);
      //   const newData = response.data.records;
      //   setViewSelectedZone(newData);
      //   setIsLoading(false);
        
      // }
      else if(coNoValue === 'cc0018'){
        const response = await axios.get(`https://worxit.co.za/api/${freedom}/view/viewZones.php`);
        const newData = response.data.records;
        setViewSelectedZone(newData);
        setIsLoading(false);
      }
     
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data: ", error);
    }
  };
    
useEffect(() => {
    ZonesTable(); // Initial call
    const intervalId = setInterval(ZonesTable, 3000);
    return () => clearInterval(intervalId);
  }, [setViewSelectedZone]); // Empty dependency array to run this effect only once

   
  const viewSites = site.site; // Assuming 'site' is an object


  useEffect(() => {
    console.log('user.zone:', user.zone);
    setCheckedZones([...user.zone]);
  }, [user.zone]);

useEffect(() => {
  // ...
  const zones = viewSelectedZone.filter((zone) =>
    viewSites.includes(zone.site)
  );
  console.log('zones: ', zones)
  // Use the set function to update the state
  setFilteredZones(zones);
}, [viewSites, viewSelectedZone]);

  
  console.log('filteredZones: ', filteredZones)

  const handleUpdate=(e)=>{
    e.preventDefault();

   const sendData={
      id: user.id,
      zone: checkedZones

    }
    console.log(sendData)
    axios.post(`https://worxit.co.za/api/updateUsersZones.php`,sendData)
    .then((res)=> {
      if (res.data.success) {
        onClose();  // Close the modal only if the response is true
      } 
    })
    .catch((res) => {
      console.error(res);
     
    });
  }

  const handleCheckboxChange = (zone, isChecked) => {
    if (isChecked) {
      setCheckedZones((prevCheckedSites) => [
        ...prevCheckedSites,
        zone,
      ]);
    } else {
      setCheckedZones((prevCheckedSites) =>
        prevCheckedSites.filter(
          (checkedSite) => checkedSite !== zone
        )
      );
    }
  };
  

  // useEffect(() => {
  //   // Update editedSite when the site prop changes
  //   setEditedSite({ ...site });
  // }, [site]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="SitesZones-modal">
      <div className="SitesZones-modal-content">
        <div className='modalHeader'>
         <div className='header-title-cont'>
            <h3 className='SitesZones-title'>Zones Details</h3>
          </div>
          <div className='defaultBtnCont'>
            <span className='defaultBtn' onClick={handleUpdate}>Save</span>
          </div>
          <div onClick={onClose} className='closeContAllModals' >
            <span className='closeAllModalIcon'>
              <FaTimes />
            </span>
          </div>
        </div>
        <div className="editable-field">
        <div className='editable-field-col1'>
          <span>Site:</span>
        </div>
          
          <label style={{color: "black"}}>{editedSite.site}</label>
        </div>
        <table className="usersTbl">
            <thead>
                <tr>
                    <th>Zone ID</th>
                    <th>Zone Name</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
            {filteredZones
            .slice(pageNumber * usersPerPage, (pageNumber + 1) * usersPerPage)
            .map((zone) => (
              <tr key={zone.id}>
                <td>{zone.zoneId}</td>
                <td>{zone.zoneName}</td>
                <td>
                    <div className='checkBoxCont'>
                      <Checkbox
                        checked={checkedZones.includes(zone.zoneName)}  
                        onChange={(e) => handleCheckboxChange(zone.zoneName, e.target.checked)}
                      />
                    </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>   
        <div className="rows-per-page">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={Math.ceil(filteredZones.length / usersPerPage)}
            onPageChange={({ selected }) => setPageNumber(selected)}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
          />
            </div>
      </div>
    </div>
  );
};

export default ViewUserZonesModal;
