// EditSiteModal.js
import React, { useState, useEffect } from 'react';
import { FaTimes, FaPencilAlt } from 'react-icons/fa';
import { MdSaveAlt } from "react-icons/md";
import Loader from '../../../../Loader/Loader';
import axios from 'axios';
import { worxit, crusaders, freedom } from '../../../../ClientUpdater/ClientUpdater';
import CreateZonesModal from '../CreateZonesModal/CreateZonesModal';
import './EditSiteModal.css';
import ReactPaginate from 'react-paginate';
import EditZonesModal from '../EditZonesModal/EditZonesModal';
import DeleteZoneModal from '../DeleteZoneModal/DeleteZoneModal';
import { AtomHeaderDataState } from '../../../../RecoilState/AtomHeaderDataState';
import { useRecoilValue } from 'recoil';

// ... (import statements)

const EditSiteModal = ({ isOpen, onClose, site }) => {
  const headerData = useRecoilValue(AtomHeaderDataState)
  const coNoValue = headerData[0].coNo;
  const [viewSelectedZone, setViewSelectedZone] = useState([])
  const [editableField, setEditableField] = useState(null);
  const [editedSite, setEditedSite] = useState({ ...site });
  const [isLoading, setIsLoading] = useState(false);
  const [showCreateZonesModal, setShowCreateZonesModal] = useState(false);
  const [message, setMessage] = useState("");
  const [filteredZones, setFilteredZones] = useState([]);
  const [selectedZone, setSelectedZone] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const usersPerPage = rowsPerPage;
  const [pageNumber, setPageNumber] = useState(0);
  const [isEditZoneModalOpen, setIsEditZoneModalOpen] = useState(false)
  const [isDeleteZoneModalOpen, setDeleteZoneModalOpen] = useState(false);
  const [zoneToDelete, setZoneToDelete] = useState(null);

  const ZonesTable = async () => {
    try {
      if(coNoValue === 'cc000'){
        const response = await axios.get(`https://worxit.co.za/api/${worxit}/view/viewZones.php`);
        const newData = response.data.records;
        setViewSelectedZone(newData);
        setIsLoading(false);
        
      }
      // else if(coNoValue === 'cc004'){
      //   const response = await axios.get(`https://worxit.co.za/api/${crusaders}/view/viewZones.php`);
      //   const newData = response.data.records;
      //   setViewSelectedZone(newData);
      //   setIsLoading(false);
        
      // }
      else if(coNoValue === 'cc0018'){
        const response = await axios.get(`https://worxit.co.za/api/${freedom}/view/viewZones.php`);
        const newData = response.data.records;
        setViewSelectedZone(newData);
        setIsLoading(false);
      }
     
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data: ", error);
    }
  };
    
useEffect(() => {
    ZonesTable(); // Initial call
    const intervalId = setInterval(ZonesTable, 3000);
    return () => clearInterval(intervalId);
  }, [setViewSelectedZone]); // Empty dependency array to run this effect only once

  const handleEditClick = (fieldName) => {
    setEditableField(fieldName);
  };

  
  const viewSites = site.site; // Assuming 'site' is an object

  
useEffect(() => {
  // Extract the 'site' properties from viewSites
  

  // Filter the zones based on the siteValues
  const zones = viewSelectedZone.filter((zone) =>
  viewSites.includes(zone.site)
  );

  // Set the filtered zones to the state
  setFilteredZones(zones);
}, [viewSites, viewSelectedZone]);
  
  console.log('filteredZones: ', filteredZones)

  const handleUpdateSubmit = (e) => {
    e.preventDefault();
  
    const sendData={
      id: site.id,
      site: editedSite.site,
      siteAddress: editedSite.siteAddress   
     }
     console.log(sendData)
     
     let apiUrl;
     if (coNoValue === 'cc000') {
       apiUrl = `https://worxit.co.za/api/${worxit}/update/updateSite.php`;
     } 
    //  else if (coNoValue === 'cc004') {
    //    apiUrl = `https://worxit.co.za/api/${crusaders}/update/updateSite.php`;
    //  }  
     else if (coNoValue === 'cc0018') {
      apiUrl = `https://worxit.co.za/api/${freedom}/update/updateSite.php`;
    }  
     axios.post(apiUrl, sendData)
     .then((res)=> {
       if (res.data.success) {
         onClose();  // Close the modal only if the response is true
       } else {
         setMessage(res.data.message);
       }
       // setMessageSuc(res.data.messageSuc);
       // setMessageError("");
       // setFormData({username:"", password:"",confirmPassword:"",role:"",coNo:"", zone:""})
     })
     .catch((res) => {
      console.error("Error updating site:", res);
      setMessage("An error occurred during the update");
      
     });
  }

  const handleUpdateClick = () => {
     // After updating, switch back to view mode
    setEditableField(null);
  };

  const handleInputChange = (e) => {
    // Update the editedSite state when input values change
    const { name, value } = e.target;
    setEditedSite((prevSite) => ({
      ...prevSite,
      [name]: value,
    }));
  };

  useEffect(() => {
    // Update editedSite when the site prop changes
    setEditedSite({ ...site });
  }, [site]);

  const handleCreateZonesClick = () => {
    setShowCreateZonesModal(true);
  };

  const deleteForm = (id) => {
    setZoneToDelete(id);
    setDeleteZoneModalOpen(true);
  };

  const confirmDelete = async () => {
    try {
      if(coNoValue === 'cc000'){
         await axios.delete(`https://worxit.co.za/api/${worxit}/delete/deleteZone.php?id=${zoneToDelete}`);
        setDeleteZoneModalOpen(false);
      }else if(coNoValue === 'cc004'){
        await axios.delete(`https://worxit.co.za/api/${crusaders}/delete/deleteZone.php?id=${zoneToDelete}`);
        setDeleteZoneModalOpen(false);
      }
     
    } catch (error) {
      console.error("Error deleting form: ", error);
    }
  };

  const cancelDelete = () => {
    setZoneToDelete(null);
    setDeleteZoneModalOpen(false);
  };

  const editForm = (zone) => {
    setSelectedZone(zone);
    setIsEditZoneModalOpen(true);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="SitesZones-modal">
      <div className="SitesZones-modal-content">
        <div className='modalHeader'>
         <div className='header-title-cont'>
            <h3 className='SitesZones-title'>Site Details</h3>
          </div>
          <div className='defaultBtnCont'>
            <span className='defaultBtn' onClick={handleUpdateSubmit}>Save</span>
          </div>
          <div onClick={onClose} className='closeContAllModals' >
            <span className='closeAllModalIcon'>
              <FaTimes />
            </span>
          </div>
        </div>
        <div className="editable-field">
        <div className='editable-field-col1'>
          <span>Site:</span>
        </div>
          
          {editableField === 'site' ? (
            <div className='editable-field-col2'>
              <div className='editable-field-col2-col1'>
                 <input
                type="text"
                name="site"
                value={editedSite.site}
                onChange={handleInputChange}
              />
              </div>
             <div title='Save' className='editable-field-col2-col2'>
              <MdSaveAlt
                className="update-icon"
                onClick={handleUpdateClick}
              />
             </div>
              
            </div>
          ) : (
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{editedSite.site}</span>
              </div>
              <div title='Edit' className='editable-field-col3-col2'>
                <FaPencilAlt
                  className="edit-icon"
                  onClick={() => handleEditClick('site')}
                />
              </div>
             
            </div>
          )}
        </div>
        <div className="editable-field">
        <div className='editable-field-col1'>
          <span>Site Address:</span>
        </div>
          
          {editableField === 'siteAddress' ? (
            <div className='editable-field-col2'>
              <div className='editable-field-col2-col1'>
                 <input
                type="text"
                name="siteAddress"
                value={editedSite.siteAddress}
                onChange={handleInputChange}
              />
              </div>
             <div title='Save' className='editable-field-col2-col2'>
              <MdSaveAlt
                className="update-icon"
                onClick={handleUpdateClick}
              />
             </div>
              
            </div>
          ) : (
            <div className='editable-field-col3'>
              <div className='editable-field-col3-col1'>
                <span>{editedSite.siteAddress}</span>
              </div>
              <div title='Edit' className='editable-field-col3-col2'>
                <FaPencilAlt
                  className="edit-icon"
                  onClick={() => handleEditClick('siteAddress')}
                />
              </div>
             
            </div>
          )}
        </div>
        
        <div className='editable-field'>
          <div className='editable-field-col1' >
            <span>Zones</span>
          </div>
          <div className='editable-field-col2'>
            <span className='createZonesBtn' onClick={handleCreateZonesClick}>Create</span>
          </div>
        </div>
        <table className="usersTbl">
            <thead>
                <tr>
                    <th>Zone ID</th>
                    <th>Zone Name</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
            {filteredZones
            .slice(pageNumber * usersPerPage, (pageNumber + 1) * usersPerPage)
            .map((zone) => (
              <tr key={zone.id}>
                <td>{zone.zoneId}</td>
                <td>{zone.zoneName}</td>
                <td>
                  <div className="editDeleteIcons">
                    <div className="editRow">
                      <span
                        title="Edit Zone"
                        className="editRowIcon"
                        onClick={() => editForm(zone)}
                      >
                        <FaPencilAlt />
                      </span>
                    </div>
                    <div className="deleteRow">
                      <span
                        title="Delete Site"
                        className="deleteRowIcon"
                        onClick={() => deleteForm(zone.id)}
                      >
                        <FaTimes />
                      </span>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>   
        <div className="rows-per-page">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={Math.ceil(filteredZones.length / usersPerPage)}
            onPageChange={({ selected }) => setPageNumber(selected)}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
          />
            </div>
        
          
        {showCreateZonesModal && (
          <CreateZonesModal 
          isOpen={showCreateZonesModal}
          onClose={() => setShowCreateZonesModal(false)}
          viewSites={viewSites}/>
        )}

    {selectedZone && (
      <EditZonesModal
        isOpen={isEditZoneModalOpen}
        onClose={() => setIsEditZoneModalOpen(false)}
        zone={selectedZone}
        
      />
    )}
     <DeleteZoneModal
        isOpen={isDeleteZoneModalOpen}
        onConfirm={confirmDelete}
        onCancel={cancelDelete}
      />
      </div>
    </div>
  );
};

export default EditSiteModal;
