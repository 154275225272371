import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Styles/MyStyles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { AtomHeaderDataState } from '../RecoilState/AtomHeaderDataState';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import worxItLogo from '../Media/worxitLogoLarge.png';
import crusadersLogo from '../Media/logo.jpg';
import freedomLogo from '../Media/freedom-stationery-logo.png';


import UserModal from '../Modals/SettingsModals/UserModal/ViewUserModal/UsersModal';
import DevicesModal from '../Modals/SettingsModals/DevicesModal/ViewDeviceModal/DevicesModal'
import CompanyModal from '../Modals/SettingsModals/CompanyModal/ViewCompanyModal/CompanyModal';
import RolesModal from '../Modals/SettingsModals/RolesModal/ViewRolesModal/RolesModal';
import SitesZonesModal from '../Modals/SettingsModals/SitesZonesModal/ViewSitesZonesModal/SitesZonesModal';
import FormBuilderModal from '../Modals/SettingsModals/FormBuilderModal/FormBuilderModal';

import AccountsModal from '../Modals/UserModal/AccountsModal/AccountsModal';

const Navbar = ({ toggleSidebar,burgerClass,burgerMenu,burgerMerge,isOpen, setIsOpen, setIsSidebarOpen,closeBurgerMenu, isSettingsDropdownOpen, setIsSettingsDropdownOpen, isUserDropdownOpen, setIsUserDropdownOpen  }) => {
  
  
  const [isUserModalOpen, setIsUserModalOpen] = useState(false); 
  const [isDevicesModalOpen, setIsDevicesModalOpen] = useState(false); 
  const [isCompanyModalOpen, setIsCompanyModalOpen] = useState(false);
  const [isRolesModalOpen, setIsRolesModalOpen] = useState(false); 
  const [isSitesZonesModalOpen, setIsSiteZonesModalOpen] = useState(false);
  const [isFormBuilderModalOpen, setIsFormBuilderModalOpen] = useState(false);

  const [isAccountsModalOpen, setIsAccountsModalOpen] = useState(false);

  const headerData = useRecoilValue(AtomHeaderDataState)
  const resetHeaderData = useResetRecoilState(AtomHeaderDataState)
  const navigate = useNavigate()

  const settingsDropdownRef = useRef(null);

  const white = "#fffff"
  
//-----------ALERTS COUNTS------------
  const alertCount = 0;// This will change the alerts number for the badge with actual alerts 

//-----------CLEAR STATE WITH LOGOUT-------
  const clearStates =() =>{
    resetHeaderData()
    navigate('/')
  }

//------------SIDEBAR----------------
  const toggleSidebarMenu = () => {
    setIsOpen(!isOpen);
    setIsSettingsDropdownOpen(false);
    setIsUserDropdownOpen(false);
  };
 
//------------SETTINGS DROPDOWN-------
  const toggleSettingsDropdown = () => {
    setIsSettingsDropdownOpen(!isSettingsDropdownOpen);
    setIsSidebarOpen(false); 
    closeBurgerMenu();
    setIsUserDropdownOpen(false);
  };

//------------USER DROPDOWN----------------
  const toggleUserDropdown = () => {
    setIsUserDropdownOpen(!isUserDropdownOpen);
    setIsSidebarOpen(false); 
    closeBurgerMenu();
    setIsSettingsDropdownOpen(false);
  };



//--------SETTINGS MODALS START----------
useEffect(() => {
  const handleClickOutside = (event) => {
    if (
      settingsDropdownRef.current &&
      !settingsDropdownRef.current.contains(event.target)
    ) {
      setIsSettingsDropdownOpen(false);
    }
  };

  document.addEventListener('click', handleClickOutside);

  return () => {
    // Remove the event listener when the component unmounts
    document.removeEventListener('click', handleClickOutside);
  };
}, [setIsSettingsDropdownOpen]);

//------------USER MODAL-----------------
  const openUserModal = () => {
    setIsUserModalOpen(true);
    setIsSettingsDropdownOpen(false); 
  };

//------------Devices MODAL-----------------
const openDevicesModal = () => {
  setIsDevicesModalOpen(true);
  setIsSettingsDropdownOpen(false); 
};

//------------COMPANY MODAL-----------
  const openCompanyModal = () => {
    setIsCompanyModalOpen(true);
    setIsSettingsDropdownOpen(false);
  };

//------------ROLES MODAL------------
  const openRolesModal = () => {
    setIsRolesModalOpen(true);
    setIsSettingsDropdownOpen(false); 
  };

//------------SITE/ZONES MODAL-------------
  const openSitesZonesModal = () => {
    setIsSiteZonesModalOpen(true);
    setIsSettingsDropdownOpen(false); 
  };

//-------------END--------------------

//-------Form Builder MODAL-----------
const openFormBuilderModal = () => {
  setIsFormBuilderModalOpen(true);
  setIsSettingsDropdownOpen(false); 
};

//-------------END--------------------
 
//----------USER MODALS START----------

//------------Accounts MODAL------------
const openAccountsModal = () => {
  setIsAccountsModalOpen(true);
  setIsUserDropdownOpen(false); 
};

//-------------END--------------------
const coNoValue = headerData[0].coNo;


console.log(coNoValue);
  return (
    <nav className="navbar">
      <div
          className={`burger ${burgerClass} ${burgerMenu} ${burgerMerge} `}
          onClick={() => {toggleSidebar(); toggleSidebarMenu(); 
          }}
        >
          <div className="bar" />
          <div className="bar" />
          <div className="bar" />
        </div>
        {coNoValue === 'cc000' ? (
          <div className="logo">
            <span>
              <img className='logoSize' src={worxItLogo} alt="worxItLogo" />
            </span>
          </div>
        ) : coNoValue === 'cc004' ? (
          <div className="logo">
            <span>
              <img className='logoSize' src={crusadersLogo} alt="crusadersLogo" />
            </span>
          </div>
          ) : coNoValue === 'cc0018' ? (
            <div className="logo">
              <span>
                <img className='logoSize' src={freedomLogo} alt="freedomLogo" />
              </span>
            </div>
        ) : null}
        
      
        <div className='alertsContainer'>
          <div>
            Alerts
          </div> 
            {alertCount >= 1 ? (
                <div className="shake">
                  <Badge badgeContent={alertCount} color="error">
                    <NotificationsIcon sx={{ color: white }}/>
                  </Badge>
                </div>
              ) : (
                <Badge badgeContent={alertCount} color="error">
                  <NotificationsIcon sx={{ color: white }}/>
                </Badge>

              )}
  
          </div>
          <div className={`dropdown ${isSettingsDropdownOpen ? 'open' : ''}`}>
            <div onClick={toggleSettingsDropdown} className="dropdown-toggle" ref={settingsDropdownRef}>
              Settings 
                <div className="arrow"></div>
            </div>
            <div className="dropdown-menu">
              <ul className="menu-list">
                {/* <li onClick={openUserModal}>
                  <div className={`menu-icon`}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </div>
                    {isSettingsDropdownOpen && <span >Users</span>}
                </li>
                <li onClick={openDevicesModal}>
                  <div className={`menu-icon`}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </div>
                    {isSettingsDropdownOpen && <span >Devices</span>}
                </li>
                <li onClick={openCompanyModal}>
                  <div className={`menu-icon`}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </div>
                    {isSettingsDropdownOpen && <span >Company</span>}
                </li> */}
                {/* <li onClick={openRolesModal}>
                  <div className={`menu-icon`}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </div>
                    {isSettingsDropdownOpen && <span >Roles</span>}
                </li> */}
                <li onClick={openSitesZonesModal}>
                  <div className={`menu-icon`}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </div>
                    {isSettingsDropdownOpen && <span >Site/Zones</span>}
                </li>
                <li onClick={openFormBuilderModal}>
                  <div className={`menu-icon`}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </div>
                    {isSettingsDropdownOpen && <span >Form Builder</span>}
                </li>
              </ul>
            </div>
          </div>
          <div className={`dropdown ${isUserDropdownOpen ? 'open' : ''}`}>
            <div onClick={toggleUserDropdown} className="dropdown-toggle">
              {headerData[0].username} 
                <div className="arrow"></div>
            </div>
            <div className="dropdown-menu">
              <ul className="menu-list">
                <li onClick={openAccountsModal}>
                  <div className={`menu-icon`}>
                    <FontAwesomeIcon icon={faUserCircle} />
                  </div>
                    {isUserDropdownOpen && <span >Accounts</span>}
                </li>
                <li onClick={clearStates}>
                  <div className={`menu-icon`}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </div>
                  {isUserDropdownOpen && <span >Logout</span>}
                </li>
              </ul>
            </div>
          </div>       

        {/* ----------SETTINGS MODAL------------------- */}
          <UserModal
            isOpen={isUserModalOpen}
            onClose={() => setIsUserModalOpen(false)}
          />

          <DevicesModal
            isOpen={isDevicesModalOpen}
            onClose={() => setIsDevicesModalOpen(false)}
          />

          <CompanyModal
            isOpen={isCompanyModalOpen}
            onClose={() => setIsCompanyModalOpen(false)}
          />

          <RolesModal
            isOpen={isRolesModalOpen}
            onClose={() => setIsRolesModalOpen(false)}
          />

          <SitesZonesModal
            isOpen={isSitesZonesModalOpen}
            onClose={() => setIsSiteZonesModalOpen(false)}
          />

          <FormBuilderModal
            isOpen={isFormBuilderModalOpen}
            onClose={() => setIsFormBuilderModalOpen(false)}
          />

        {/* -------------USERS MODAL--------------- */}

        <AccountsModal
            isOpen={isAccountsModalOpen}
            onClose={() => setIsAccountsModalOpen(false)}
          />
    </nav>
  );
};

export default Navbar;
