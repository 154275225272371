import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaTimes } from 'react-icons/fa';
import './CreateZonesModal.css';
import { useRecoilValue } from 'recoil';
import {worxit, crusaders, freedom } from '../../../../ClientUpdater/ClientUpdater';
import { AtomHeaderDataState } from '../../../../RecoilState/AtomHeaderDataState';

const CreateZonesModal = ({ isOpen, onClose, viewSites }) => {
  const headerData = useRecoilValue(AtomHeaderDataState)
  const coNoValue = headerData[0].coNo;
  const [message, setMessage] = useState("");
  const [viewCompanyNo, setViewCompanyNo] = useState([]);
  const [selectedCoNo, setSelectedCoNo] = useState('');
  const [formData, setFormData] = useState({
        zoneId: '',
        zoneName: '',
        // Add other fields as needed
  });

    useEffect(() => {
      const fetchCompanyNos = async () => {
          try {
            if(coNoValue === 'cc000'){
              const response = await axios.get(`https://worxit.co.za/api/${worxit}/fetch/fetchCompanyNo.php`);
              const newData = response.data.records;
              setViewCompanyNo(newData);
              console.log('newData: ', newData)
            }
            // else if(coNoValue === 'cc004'){
            //   const response = await axios.get(`https://worxit.co.za/api/${crusaders}/fetch/fetchCompanyNo.php`);
            //   const newData = response.data.records;
            //   setViewCompanyNo(newData);
            //   console.log('newData: ', newData)
            // }
            else if(coNoValue === 'cc0018'){
              const response = await axios.get(`https://worxit.co.za/api/${freedom}/fetch/fetchCompanyNo.php`);
              const newData = response.data.records;
              setViewCompanyNo(newData);
              console.log('newData: ', newData)
            }
            
            } catch (error) {
              console.error("Error fetching data: ", error);
            }
      };
      fetchCompanyNos(); // Initial call
        const intervalId = setInterval(fetchCompanyNos, 5000);
        return () => clearInterval(intervalId);
      }, [setViewCompanyNo]); // Empty dependency array to run this effect only once

        
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
        ...formData,
        [name]: value,
        });
    };

    const handleSubmit=(e)=>{
      e.preventDefault();
  
     const sendData={
        coNo: coNoValue,
        zoneId: formData.zoneId,
        zoneName: formData.zoneName,
        site: viewSites,
       
        
      }
      console.log(sendData)
      let apiUrl;
      if (coNoValue === 'cc000') {
        apiUrl = `https://worxit.co.za/api/${worxit}/create/createZones.php`;
      } 
      // else if (coNoValue === 'cc004') {
      //   apiUrl = `https://worxit.co.za/api/${crusaders}/create/createZones.php`;
      // }
      else if (coNoValue === 'cc0018') {
        apiUrl = `https://worxit.co.za/api/${freedom}/create/createZones.php`;
      }
    
      axios.post(apiUrl, sendData)
      .then((res)=> {
        if (res.data.success) {
          onClose();  // Close the modal only if the response is true
        } else {
          setMessage(res.data.message);
        }
        // setMessageSuc(res.data.messageSuc);
        // setMessageError("");
        // setFormData({username:"", password:"",confirmPassword:"",role:"",coNo:"", zone:""})
      })
      .catch((res) => {
        console.error(res);
        setMessage(res.data.message);
       
      });
    }

  

    if (!isOpen) {
        return null;
    }

  return (
    <div className="create-zone-modal">
      <div className="create-zone-modal-content">
        <div className='modalHeader'>
          <div className='header-title-cont'>
            <h3 className="create-zone-title">Create Zones</h3>
          </div>
          <div className='defaultBtnCont'>
              <span onClick={handleSubmit} className='defaultBtn' type="submit">Add Zone</span>
          </div>
          <div onClick={onClose} className="closeContAllModals">
            <span className="closeAllModalIcon">
              <FaTimes />
            </span>
          </div>
        </div>
          <div className='createUserSelectedCoNoCont'>
              <div className='createUserCompanyNoTitle'>
                  <label className='searchName' htmlFor="CreateUserCompanyNoDropdown">Company No:</label>
              </div>
              <div className='createUserUsernameTitle'>
                 <label className='searchName'>
              {coNoValue}
              </label>
              </div>
              {/* <div className='dropdownCompanyNoCont'>
                  <select
                  id="CreateUserCompanyNoDropdown"
                  value={selectedCoNo}
                  onChange={(e) => setSelectedCoNo(e.target.value)}
                >
                  <option value="">-- Select --</option>
                  {viewCompanyNo.map((item) => (
                    <option key={item.id} value={item.coNo}>
                      {item.coNo}
                    </option>
                  ))}
                </select>
              </div> */}
            </div>
            <div className='createUserUsernameCont'>
              <div className='createUserUsernameTitle'>
                 <label className='searchName'>
              Zone ID:
              </label>
              </div>
              <div className='createUserUsername'>
                <input
                  className='createUserUsernameInput'
                  type="text"
                  name="zoneId"
                  value={formData.zoneId}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className='createUserEmpNoCont'>
              <div className='createUserEmpNoTitle'>
                <label className='searchName'>
              Zone Name:
              </label>
              </div>
              <div className='createUserEmpNo'>
                <input
                className='createUserEmpNoInput'
                type="text"
                name="zoneName"
                value={formData.zoneName}
                onChange={handleChange}
              />
              </div>            
            </div>
             
             <div className='messagesCont'>
                {message && <p className='error-message'>{message}</p>}
             </div>
          
      </div>
     
    </div>
    
  );
};

export default CreateZonesModal;
