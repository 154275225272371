import { Route, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";
import Login from "./Pages/Login/Login";
import Main from "./Pages/Main/Main";

const AppRoutes = () => {
    return (
        <RecoilRoot>            
            <Routes>
                <Route path="/" element={
                    <Login />
                } />
                 <Route path="/main" element={
                    <Main />
                } />
              
              </Routes>
              
      </RecoilRoot>
    );
}

export default AppRoutes;