import React from 'react';
import '../../FormBuilder.css';

const QRSingleScanElement = ({ component, isRequired }) => {
  const renderElement = () => {
    return (
      component.elementName === 'qrSingleScan' && (
        <label className='titleTextLbl'>
            {component.fieldName || "QR Single Scan"}
            <span className='requiredContainer' style={{ color: 'red' }}>{isRequired(component)}</span>
        </label>
      )
    );
  };

  return (
    <div className='renderAllElements'>
      {renderElement()}
    </div>
  );
};

export default QRSingleScanElement;
