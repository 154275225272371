import React from 'react';
import '../../FormBuilder.css';

const DropdownElement = ({ component, isRequired }) => {
  const renderElement = () => {
    return (
      component.elementName === 'dropdown' && (
        <label className='titleTextLbl'>
            {component.fieldName }
            <span className='requiredContainer' style={{ color: 'red' }}>{isRequired(component)}</span>
            <select className='inputComponent' type="dropdown">
                {component.fieldData ? (
                component.fieldData.map((option, index) => (
                    <option key={index} value={option}>
                    {option}
                    </option>
                ))
                ) : (
                <>
                    <option value="Option 1">Option 1</option>
                    <option value="Option 2">Option 2</option>
                    <option value="Option 3">Option 3</option>
                </>
                )}
            </select>
        </label>
      )
    );
  };

  return (
    <div className='renderAllElements'>
      {renderElement()}
    </div>
  );
};

export default DropdownElement;
