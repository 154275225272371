import React from 'react';
import '../../FormBuilder.css';

const TextAreaElement = ({ component, isRequired }) => {
  const renderElement = () => {
    return (
      component.elementName === 'textarea' && (
        <label className='titleTextLbl' >
            {component.fieldName }
                <span className='requiredContainer' style={{ color: 'red' }}>{isRequired(component)}</span>
                <textarea className='inputComponent' type="text" placeholder={component.fieldName} readOnly/>
        </label>
      )
    );
  };

  return (
    <div className='renderAllElements'>
      {renderElement()}
    </div>
  );
};

export default TextAreaElement;
