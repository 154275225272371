// DeleteConfirmationModal.js
import React from 'react';
import './DeleteZoneModal.css'

const DeleteZoneModal = ({ isOpen, onConfirm, onCancel }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="Delete-zone-modal">
      <div className="Delete-zone-modal-content">
        <h3 className='Delete-zone-modal-title'>Confirm Deletion</h3>
        <label className='confirmQues'>Are you sure you want to delete this zone?</label>
        <div className='confirmCont'>
            <div className='yesCont'>
                <button className='yesBtn' onClick={onConfirm}>Yes</button>
            </div>
            <div className='noCont'>
                <button className='noBtn' onClick={onCancel}>No</button>
            </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteZoneModal;
