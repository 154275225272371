import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import './ReportDetailsModal.css';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ReportDetailsLoader from './ReportDetailsLoader/ReportDetailsLoader';
import { FaTimes } from 'react-icons/fa';
import worxitLogo from '../../../Media/worxitLogoLarge.png'
import crusadersLogo from '../../../Media/logo.jpg'
import freedomLogo from '../../../Media/freedom-stationery-logo.png'
import { MapContainer, TileLayer, Marker,Popup } from 'react-leaflet';
import L from 'leaflet';
import locationMarker from '../../../Media/locationMarker.png'
import domtoimage from 'dom-to-image';
import { worxit, crusaders, freedom } from '../../../ClientUpdater/ClientUpdater';
import ExportReportToExcel from './ExportReportToExcel/ExportReportToExcel';
import { AtomHeaderDataState } from '../../../RecoilState/AtomHeaderDataState';
import { useRecoilValue } from 'recoil';

const ReportDetailsModal = ({ reportId, onClose }) => {
  const [reportDetails, setReportDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [markerPopup, setMarkerPopup] = useState(false);
  const mapRef = useRef(null);
  const headerData = useRecoilValue(AtomHeaderDataState)
  const coNoValue = headerData[0].coNo;

  useEffect(() => {
    const fetchReportDetails = async () => {
     
      try {
        if(coNoValue === 'cc000'){
          const response = await axios.get(`https://worxit.co.za/api/${worxit}/view/viewReportDetails.php?reportId=${reportId}`);
          const newData = response.data.records;
          console.log("Response data:", newData); // Log the response data
            setReportDetails(newData);
            setIsLoading(false); // Set loading to false when data is fetched
        }
        // else if(coNoValue === 'cc004'){
        //   const response = await axios.get(`https://worxit.co.za/api/${crusaders}/view/viewReportDetails.php?reportId=${reportId}`);
        //   const newData = response.data.records;
        //   console.log("Response data:", newData); // Log the response data
        //     setReportDetails(newData);
        //     setIsLoading(false); // Set loading to false when data is fetched
        // }
        else if(coNoValue === 'cc0018'){
          const response = await axios.get(`https://worxit.co.za/api/${freedom}/view/viewReportDetails.php?reportId=${reportId}`);
          const newData = response.data.records;
          console.log("Response data:", newData); // Log the response data
            setReportDetails(newData);
            setIsLoading(false); // Set loading to false when data is fetched
        }
      
      } catch (error) {
        console.error("Error fetching report details: ", error);
        setIsLoading(false); // Set loading to false on error as well
      }
    };

    fetchReportDetails();
  }, [reportId, coNoValue]);

  
  const filteredData = reportDetails.flatMap((report) => {
    let data = [];

    if (Array.isArray(report.reportData)) {
        // Handle the case where report.reportData is an array
        data = report.reportData;
    } else if (typeof report.reportData === 'object' && Array.isArray(report.reportData.data)) {
        // Handle the case where report.reportData is an object with a data property
        data = report.reportData.data;
    } else {
        // Handle other cases or log an error
        console.error("Invalid data structure:", report.reportData);
    }

    return data
        .filter((field) => field && field.elementId !== 'et' && field.elementId !== 'eemail')
        .map((field) => {
            let fieldValue = field.fieldData;

            // Convert boolean field value to "Yes" or "No"
            if (field.elementId === 'ebool') {
                fieldValue = field.fieldData ? 'Yes' : 'No';
            }

          //   if (typeof fieldValue === 'object') {
          //     // Convert the object into a string or another suitable format
          //     fieldValue = JSON.stringify(fieldValue);
          // }

            // if (field.elementId === 'e2dscandisk' || field.elementId === 'eqrscandisk'){
            //   // fieldValue= JSON.stringify(fieldValue)
            //   const { vehicleReg, vehicleMake, vehicleColour, vehicleModel, vehicleExpires, expiredStatus, accessStatus } = fieldValue;

            //   // Format the properties as desired
            //   fieldValue = `Vehicle Reg: ${vehicleReg} \nVehicle Make: ${vehicleMake} \nVehicle Colour: ${vehicleColour} \nVehicle Model: ${vehicleModel}\nVehicle Expires: ${vehicleExpires}\nExpired Status: ${expiredStatus}\nAccess Status: ${accessStatus}`;
          
            // }

            return {
                id: field.id,
                elementId: field.elementId,
                fieldName: field.fieldName,
                fieldData: fieldValue,
            };
        });
});

console.log('reportDetails: ', reportDetails)

const combinedData = [
  ...reportDetails.map((report) => {
    return ["Report Name", report.reportName];
  }),
  ...filteredData.map((field) => {
    if (field.elementId === 'egps') {
      // If the field is GPS Position, format latitude and longitude
      return [
        field.fieldName,
        `${field.fieldData.latitude}, ${field.fieldData.longitude}`
      ];
    } else if (field.elementId === 'eurl' || field.elementId === 'eurls' || field.elementId === 'esign') {
      // If the field is eurl, eurls, or esign, just return the field name
      return [field.fieldName];
    } else if (field.elementId === 'evid') {
      // If the field is evid, display fieldName and fieldData, or a message if empty
      return [
        field.fieldName,
        field.fieldData ? field.fieldData : "No video capture"
      ];
    } else if (Array.isArray(field.fieldData)) {
      // If fieldData is an array, concatenate properties for each object with a line break
      return [
        field.fieldName,
        field.fieldData.map(item => `${item.qtyNo}, ${item.barcode}, ${item.description}`).join('\n')
      ];
    // }else if(typeof field.fieldData === 'object'){
    //   return [field.fieldName, `Vehicle Reg: ${field.fieldData.vehicleReg}, \nVehicle Make: ${field.fieldData.vehicleMake}, \nVehicle Colour: ${field.fieldData.vehicleColour}, \nVehicle Model: ${field.fieldData.vehicleModel}, \nVehicle Expires: ${field.fieldData.vehicleExpires}, \nExpired Status: ${field.fieldData.vehicleMake}, \nAccess Status: ${field.fieldData.accessStatus}`
    //           || field.fieldData]     

    }
     else {
      // If fieldData is not an array and not GPS Position, use it directly
      return [field.fieldName, field.fieldData];
    }
  }),
];




  const customIcon = L.icon({
    iconUrl: locationMarker,
    iconSize: [14, 18], // Adjust the size as needed

    });

  const handleExportToPDF = async() => {
    // Create a new jsPDF instance
    const pdf = new jsPDF();

    // Set column headers
    const reportDate = new Date().toLocaleString();
    pdf.setFontSize(8);
    //pdf.setFont('Lato-Regular', 'normal');
    pdf.setTextColor(50, 50, 50);
    pdf.text(`${reportDate}`, 165, 10);

    const reportIdData = reportDetails.map((report) => {
     return(report.reportId)
    })

    pdf.setTextColor(50, 50, 50);
    pdf.setFontSize(8);
    pdf.text(`${reportIdData}`, 165, 15);

    if(coNoValue === 'cc000'){
      pdf.addImage(worxitLogo, "PNG", 15, 5, 50, 20);
    }
    // else if(coNoValue === 'cc004'){
    //   pdf.addImage(crusadersLogo, "PNG", 15, 5, 50, 20);
    // }
    else if(coNoValue === 'cc0018'){
      pdf.addImage(freedomLogo, "PNG", 15, 5, 50, 20);
    }
   

    const mapContainer = mapRef.current;
    let canvas;
    if (mapContainer) {
      canvas = await domtoimage.toPng(mapContainer.getContainer());
    } 

    pdf.autoTable({
      body: combinedData,
      startY: 35,
      rowPageBreak: 'avoid',
      styles: {
        textColor: [50, 50, 50],
        // lineWidth: 0.5,
      },
      alternateRowStyles: {
        fillColor: '#DADADA'
      },
      columnStyles: {
        0: { // First column
          cellWidth: 60, // Set the specific width for the first column
        },
        1: { // Second column
          cellWidth: 'auto', // Let it take the remaining width
        },
      },
      didParseCell: function (data) {
        const field = filteredData[data.row.index - 1];
    
        if (field && field.elementId === 'eurl' && data.column.index === 1) {
          data.row.height = 80;
        }
        if (field && field.elementId === 'eurls' && data.column.index === 1) {
          data.row.height = 80;
        }
        if (field && field.elementId === 'esign' && data.column.index === 1) {
          data.row.height = 60;
        }
        if (field && field.elementId === 'egps' && data.column.index === 1) {
          data.row.height = 60;
        }
      },
        
      didDrawCell: async (data, cell) => {
        const field = filteredData[data.row.index - 1]; // Adjust index to match your data

        if (field && field.elementId === 'egps' && data.column.index === 1) {
          if (canvas) {
            pdf.addImage(canvas, 'PNG', data.cell.x + 2, data.cell.y + 10, 75, 75);
            // data.row.height = 60;
          }
        }
           
        if (field && field.elementId === 'eurl' && data.column.index === 1 ) {
          if (field.fieldData && field.fieldData.trim() !== '') { // Check for non-empty string
            const imageUrl = Array.isArray(field.fieldData)
              ? field.fieldData.map((url) => `data:image/png;base64,${url}`)
              : [`data:image/png;base64,${field.fieldData}`];
    
            imageUrl.forEach((imageUrl, index) => {
              pdf.addImage(imageUrl, 'PNG', data.cell.x + 2, data.cell.y + 2 + index * 30, 75, 75);
            });
          } 
           else {
             pdf.text("No image captured", data.cell.x + 2, data.cell.y + 5); // Display text if no image
              data.row.height = 10;
            //return null
          }
        }
    
        if (field && field.elementId === 'eurls' && data.column.index === 1) {
          if (field.fieldData && field.fieldData.trim() !== '') { // Check for non-empty string
            const imageUrls = Array.isArray(field.fieldData)
              ? field.fieldData.map((url) => `data:image/png;base64,${url}`)
              : [`data:image/png;base64,${field.fieldData}`];
    
            imageUrls.forEach((imageUrl, index) => {
              pdf.addImage(imageUrl, 'PNG', data.cell.x + 2, data.cell.y + 2 + index * 30, 75, 75);
              
            });
          } else {
            pdf.text("No image captured", data.cell.x + 2, data.cell.y + 5); // Display text if no image
            data.row.height = 10;
          }
        }
    
        if (field && field.elementId === 'esign' && data.column.index === 1) {
          if (field.fieldData && field.fieldData.trim() !== '') { // Check for non-empty string
            const imageUrlSign = Array.isArray(field.fieldData)
              ? field.fieldData.map((url) => `data:image/png;base64,${url}`)
              : [`data:image/png;base64,${field.fieldData}`];
    
            imageUrlSign.forEach((imageUrlSign, index) => {
              pdf.addImage(imageUrlSign, 'PNG', data.cell.x + 2, data.cell.y - 10 + index * 30, 55, 55);
            });
          } else {
            pdf.text("No signature captured", data.cell.x + 2, data.cell.y + 5); // Display text if no image
            data.row.height = 10;
          }
        }
      },
    });
    
        
    const reportNameId = reportDetails.map((report) => {
      return [`${report.reportName}_${report.reportId}`];
    })
     // Save the PDF with a specific name
    pdf.save(`${reportNameId}.pdf`);
  };
  
    
  return (
    <div className="reportsDetails-modal">
      <div className="reportsDetails-modal-content">
        {/* <div className='reportTitleCont'>
          {reportDetails.map((report) => (
            <label className='searchName' key={report.id}>{report.reportName}</label>
          ))}
        </div> */}

        {isLoading ? (
          <div>
            <ReportDetailsLoader />
          </div>
        ) : (
          <>
          <table className='reportsDetailsTitleTable'>
            <tbody className='reportsDetailsTableBody'>
              {reportDetails.map((report, index) => (
                <tr className='reportsDetailsRow' key={report.id}>
                  <td className='fieldNameRow'>Report Name</td>
                  <td className='fieldDataRow'> {report.reportName}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <table className='reportsDetailsTable'>
          <tbody className='reportsDetailsTableBody'>
            {filteredData.map((field, index) => (
              <tr className='reportsDetailsRow' key={field.id}>
                <td className='fieldNameRow'>
                  {field.elementId === 'ets' ? (
                  <span style={{ fontWeight: 'bold', fontSize: '14px' }}>{field.fieldName}</span>
                ) : (
                field.fieldName
              )}</td>
                <td className='fieldDataRow'>
                  {field.elementId === 'egps' ? (
                    // If the field is GPS Position, render latitude and longitude
                    <>
                      <div>
                        {field.fieldData.latitude},{field.fieldData.longitude}
                      </div>
                      <div>
                        <MapContainer
                          center={[field.fieldData.latitude, field.fieldData.longitude]}
                          zoom={18}
                          style={{ height: "200px", width: "50%" }}
                          className="map"
                          ref={mapRef}
                          zoomControl={false}
                        >
                          <TileLayer
                            attribution="Map data &copy; OpenStreetMap contributors"
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                          />

                          <Marker position={[field.fieldData.latitude, field.fieldData.longitude]} icon={customIcon}>
                            <Popup onClose={() => setMarkerPopup(false)}>
                              <div>
                                Latitude: {[field.fieldData.latitude]}<br />
                                Longitude: {[field.fieldData.longitude]}<br />
                              </div>
                            </Popup>
                          </Marker>
                        </MapContainer>  
                      </div>
                    </>
                 ) : field.elementId === 'eurl' ? (
                    field.fieldData ? (
                      <img className='imageEurl' src={`data:image/png;base64,${field.fieldData}`} alt={field.fieldName} />
                    ) : (
                      <span>No image captured</span>
                    )
                  ) : field.elementId === 'eurls'  ? (
                    field.fieldData ? (
                      <img className='imageEurl' src={`data:image/png;base64,${field.fieldData}`} alt={field.fieldName} />
                    ) : (
                      <span>No image captured</span>
                    )
                  ) : field.elementId === 'esign'  ? (
                    field.fieldData ? (
                      <img className='imageEurl' src={`data:image/png;base64,${field.fieldData}`} alt={field.fieldName} />
                    ) : (
                      <span>No signature captured</span>
                    )
                  ) : Array.isArray(field.fieldData) ? (
                    // If it's an array, render each object's "description" property
                    field.fieldData.map((item, itemIndex) => (
                      <div key={itemIndex}>{item.qtyNo}, {item.barcode}, {item.description}</div>
                    ))
                  ) : field.elementId === 'evid'  ? (
                    field.fieldData ? (
                      field.fieldData
                  ) : (
                      <span>No video captured</span>
                    )
                  ): field.elementId === 'e2dscandisk' || field.elementId === 'eqrscandisk' ? (
                  
                    <div>
                    {/* Log the entire fieldData object */}
                    {console.log('field.fieldData:', field.fieldData)}
              
                    {/* Display properties */}
                    {typeof field.fieldData === 'object' ? (
                      <>
                        <div>Vehicle Reg: {field.fieldData.vehicleReg}</div>
                        <div>Vehicle Make: {field.fieldData.vehicleMake}</div>
                        <div>Vehicle Colour: {field.fieldData.vehicleColour}</div>
                        <div>Vehicle Model: {field.fieldData.vehicleModel}</div>
                        <div>Vehicle Expires: {field.fieldData.vehicleExpires}</div>
                        <div>Expired Status: {field.fieldData.expiredStatus}</div>
                        <div>Access Status: {field.fieldData.accessStatus}</div>
                        {/* Add other properties as needed */}
                      </>
                    ) : (
                      <div>{field.fieldData}</div>
                    )}
                  </div>   
                  ): (
                    
                    // If it's not an array and not GPS Position or Image, render the fieldData directly
                    field.fieldData
                  
                  )}
                </td>
                
              </tr>
              
            ))}
          </tbody>
                    
          </table>
       
        
        <div className='btnsContainer'>
          <div className='exportToPdfCont'>
            <button className='exportToPdfBtn' onClick={handleExportToPDF}>Export to PDF</button>
          </div>
          <ExportReportToExcel reportId={reportId}/>
        </div> 
        </>
        )} 
        {/* <div className='deleteComponent'>
             <button className='closebtnContReportDetails' onClick={onClose}>X</button> 
            <span className='deleteIcon'></span>
        </div>  */}
        <div onClick={onClose} className='closeContReportDetails' >
          <span className='closeReportDetailsIcon'>
            <FaTimes />
          </span>
        </div>
      </div>
    </div>
  );
};

export default ReportDetailsModal;
