import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaTimes } from 'react-icons/fa';
import './CreateSitesModal.css';
import { worxit, crusaders, freedom } from '../../../../ClientUpdater/ClientUpdater';
import { AtomHeaderDataState } from '../../../../RecoilState/AtomHeaderDataState';
import { useRecoilValue } from 'recoil';

const CreateSiteModal = ({ isOpen, onClose }) => {
    const headerData = useRecoilValue(AtomHeaderDataState)
    const coNoValue = headerData[0].coNo;
    const [message, setMessage] = useState("");
    const [viewCompanyNo, setViewCompanyNo] = useState([]);
    const [selectedCoNo, setSelectedCoNo] = useState('');
    const [formData, setFormData] = useState({
        site: '',
        siteAddress: '',
        // Add other fields as needed
    });

    useEffect(() => {
      const fetchCompanyNos = async () => {
          try {
            if(coNoValue === 'cc000'){
              const response = await axios.get(`https://worxit.co.za/api/${worxit}/fetch/fetchCompanyNo.php`);
              const newData = response.data.records;
              setViewCompanyNo(newData);
            }
            // else if(coNoValue === 'cc004'){
            //   const response = await axios.get(`https://worxit.co.za/api/${crusaders}/fetch/fetchCompanyNo.php`);
            //   const newData = response.data.records;
            //   setViewCompanyNo(newData);
            // }
            else if(coNoValue === 'cc0018'){
              const response = await axios.get(`https://worxit.co.za/api/${freedom}/fetch/fetchCompanyNo.php`);
              const newData = response.data.records;
              setViewCompanyNo(newData);
            }
            
            } catch (error) {
              console.error("Error fetching data: ", error);
            }
      };
      fetchCompanyNos(); // Initial call
        const intervalId = setInterval(fetchCompanyNos, 5000);
        return () => clearInterval(intervalId);
      }, [setViewCompanyNo]); // Empty dependency array to run this effect only once

        
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
        ...formData,
        [name]: value,
        });
    };

    const handleSubmit = (e) => {
      e.preventDefault();
    
      const sendData = {
        coNo: coNoValue,
        site: formData.site,
        siteAddress: formData.siteAddress,
      };
    
      console.log(sendData);
    
      let apiUrl;
      if (coNoValue === 'cc000') {
        apiUrl = `https://worxit.co.za/api/${worxit}/create/createSites.php`;
      } 
      // else if (coNoValue === 'cc004') {
      //   apiUrl = `https://worxit.co.za/api/${crusaders}/create/createSites.php`;
      // }
      else if (coNoValue === 'cc0018') {
        apiUrl = `https://worxit.co.za/api/${freedom}/create/createSites.php`;
      }
    
      axios.post(apiUrl, sendData)
        .then((res) => {
          if (res.data.success) {
            onClose(); // Close the modal only if the response is true
          } else {
            setMessage(res.data.message);
          }
          // setMessageSuc(res.data.messageSuc);
          // setMessageError("");
          // setFormData({username:"", password:"",confirmPassword:"",role:"",coNo:"", zone:""})
        })
        .catch((error) => {
          console.error(error);
          setMessage("An error occurred while processing the request.");
        });
    };
    

  

    if (!isOpen) {
        return null;
    }

  return (
    <div className="create-site-modal">
      <div className="create-site-modal-content">
        <div className='modalHeader'>
          <div className='header-title-cont'>
            <h3 className="create-site-title">Create Site</h3>
          </div>
          <div className='defaultBtnCont'>
            <span onClick={handleSubmit } className='defaultBtn' type="submit">Add Site</span>
          </div>
          <div onClick={onClose} className="closeContAllModals">
            <span className="closeAllModalIcon">
              <FaTimes />
            </span>
          </div>
        </div>
          <div className='createUserSelectedCoNoCont'>
              <div className='createUserCompanyNoTitle'>
                  <label className='searchName' htmlFor="CreateUserCompanyNoDropdown">Company No:</label>
              </div>
              <div className='createUserUsername'>
                 <label className='searchName'>
              {coNoValue}
              </label>
              </div>
              {/* <div className='dropdownCompanyNoCont'>
                  <select
                  id="CreateUserCompanyNoDropdown"
                  value={selectedCoNo}
                  onChange={(e) => setSelectedCoNo(e.target.value)}
                >
                  <option value="">-- Select --</option>
                  {viewCompanyNo.map((item) => (
                    <option key={item.id} value={item.coNo}>
                      {item.coNo}
                    </option>
                  ))}
                </select>
              </div> */}
            </div>
            <div className='createUserUsernameCont'>
              <div className='createUserUsernameTitle'>
                 <label className='searchName'>
              Site:
              </label>
              </div>
              <div className='createUserUsername'>
                <input
                  className='createUserUsernameInput'
                  type="text"
                  name="site"
                  value={formData.site}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className='createUserEmpNoCont'>
              <div className='createUserEmpNoTitle'>
                <label className='searchName'>
              Site Address:
              </label>
              </div>
              <div className='createUserEmpNo'>
                <input
                className='createUserEmpNoInput'
                type="text"
                name="siteAddress"
                value={formData.siteAddress}
                onChange={handleChange}
              />
              </div>            
            </div>
             
             <div className='messagesCont'>
                {message && <p className='error-message'>{message}</p>}
             </div>         
      </div>
    </div>
    
  );
};

export default CreateSiteModal;
