// UserModal.js
import React from 'react';
import './FormBuilderModal.css';
import FormBuilder from './FormBuilder';
import { FaTimes } from 'react-icons/fa';

const FormBuilderModal = ({ isOpen, onRequestClose, selectedForm, columns, setColumns, showSelectedForm,setShowCreateForm }) => {
  
  if (!isOpen) {
    return null;
  }

return (
    <div className="createForm-modal">
      <div className="createForm-modal-content">
        <FormBuilder 
          selectedForm={selectedForm}
          columns={columns}
          setColumns={setColumns}
          showSelectedForm={showSelectedForm}
          setShowCreateForm={setShowCreateForm}
          onRequestClose={onRequestClose}
        />
        {/* <div onClick={onRequestClose} className='closeContCreateForm'>
          <span className='closeCreateFormIcon'>
            <FaTimes />
          </span>
        </div> */}
      </div>
    </div>
  );
};

export default FormBuilderModal;
