import React, { useState } from 'react';
import axios from 'axios';
import { useSetRecoilState } from 'recoil';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import { AtomHeaderDataState } from '../../components/RecoilState/AtomHeaderDataState';
import { IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
//import logo from '../../components/Media/logo.jpg';
import logo from '../../components/Media/worxitLogoLarge.png'
import ztLogo from '../../components/Media/ZT.png'
import { allClients } from '../../components/ClientUpdater/ClientUpdater';

const Login = () => {
  //const [username, setUsername] = useState(''); // Initialize state for username
  //const [password, setPassword] = useState(''); // Initialize state for password
  const [message, setMessage ] = useState('')
  const setHeaderData = useSetRecoilState(AtomHeaderDataState)
  const navigate = useNavigate()

  const [values, setValues] = useState({
    username: "",
    password: "",
    coNo: "",
    status: "",
    role: "",
    regions: "",
    site: "",
		showPass: false,
	});

  const handlePassVisibilty = () => {
		setValues({
			...values,
			showPass: !values.showPass,
		});
	};

  // Handle changes in the username input
  const handleUsernameChange = (e) => {
    setValues({ ...values, username: e.target.value })
  };

  // Handle changes in the password input
  const handlePasswordChange = (e) => {
    setValues({ ...values, password: e.target.value })
  };

  // Inside your React Login component
  const handleLogin = (e) => {
    e.preventDefault(); // Prevent the form from submitting the traditional way

    axios
    //`https://worxit.co.za/api/${updatedValue}/users.php`
      .post(`https://worxit.co.za/api/users.php`, {
        username: values.username,
        password: values.password,
        coNo: values.coNo,
        status: values.status,
        role: values.role,
        regions: values.regions,
        site: values.site,

      })
      .then((response) => {
        const data = response.data;
        if (data.success) {
          // Authentication successful, redirect or show a success message
          setHeaderData(oldHeaderData => [
						...oldHeaderData,
						{
              username: values.username,
              coNo: data.coNo,
              status: data.status,
              role: data.role,
              regions: data.regions,
              site: data.site, 
            												
						},
					]);
          navigate('/main');
        } else {
          // Authentication failed, display an error message
          setMessage(data.message)
          console.error(data.message);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };


  return (
    <div className="container">
      <div className="form-box">
        <div className="header-form">
          <div className="imageContainer">
            <img src={logo} alt="logo" className="imageIcon" />
          </div>
        </div>
        <div className="body-form">
          <form onSubmit={handleLogin}>
            <div className="userContainer">
              <div className="iconContainer">
                <span className="userIcon">
                  <FontAwesomeIcon icon={faUser} />
                </span>
              </div>
              <div className="inputContainer">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Username"
                  //value={username}
                  onChange={handleUsernameChange} // Attach onChange event handler
                />
              </div>
            </div>
            <div className="passwordContainer">
              <div className="iconContainer">
                <span className="passwordIcon">
                  <FontAwesomeIcon icon={faLock} />
                </span>
              </div>
              <div className="inputContainer">
              <input
                type={values.showPass ? 'text' : 'password'} // Dynamically set the input type
                className="form-control"
                placeholder="Password"
                // value={password}
                onChange={handlePasswordChange}
              />
            <div className='visibilityIcon'>
              <IconButton
                onClick={handlePassVisibilty}
                aria-label="toggle password"
                className="passwordVisibilityIcon"
              >
                {values.showPass ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
            </div>
            
          
              </div>
            </div>
            <div className="btnContainer">
              <button type="submit" className="btn btn-secondary btn-block">
                LOGIN
              </button>
            </div>
            <div className='loginMessage'>
              {/* <div><a href="#">Forgot your password</a></div> */}
              {message}
            </div>
          </form>
          <div className='footerContainer'>
            <div className="footerImageCont">
              <img src={ztLogo} alt="ztlogo" className="footerImage" />
            </div>
            <div className='footerTextCont'>
              <label className='footerText'>Powered by Zamatrack</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
